<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section mvh dashborad-inner uploadpage">
  	<div class="container">
	    <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>
	        <div class="dashborad-rightsider">
	        	<div id="content" *ngIf="taskData">
                                
                    <h1 *ngIf="total_rows !== 0">We found {{total_rows}} rows</h1>
                   	<p>Please follow the wizard steps to complete the setup and configuration of your dataset you want to process.</p>
                   
                    <h3 class="mt-16 mb-6 opacity-25 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/doesheader/'+task_id]"> <b>1.</b> Does your first row contain headings? </a> </h3>
                    <h3 class="mt-3 mb-6 opacity-50 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/assignheader/'+task_id]"> <b>2.</b> Please assign headings for your import </a> </h3>
                    <h3 class="mt-3 mb-6 opacity-75 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/assigndatatype/'+task_id]"> <b>3.</b> Please assign data types to your columns </a> </h3>
                    <h3 class="mt-3 mb-6 commanlsittecxt"> <b>4.</b> Allocate Teams</h3>
                    
                    <form [formGroup]="allocateTeamForm" (ngSubmit)="saveAllocateTeam()" style="padding: 0;">
                        <div class="w-full relative mt-12">
                            <div class="d-flex justify-content-center teamlabelsection">
                                <ul>
                                    <li *ngFor="let teamData of teamsData;let i=index;">
                                        <label class="teamlabsel shadow rounded-lg" [formGroup]="t.controls[i]">
                                            <input type="checkbox" class="" formControlName="is_selected"> {{teamData.title}}
                                        </label>
                                    </li>
                                    <li>
                                    	<a [routerLink]="" (click)="createTeamModal()" class="teamlabsel shadow rounded-lg">
                                          <img src="assets/images/plus-square-solid.svg" alt="img"> Create Team
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                         
                        <div class="w-full d-sm-flex mt-16">
                         	<button class="bg-blue-800">Allocate Teams</button>
                        </div>
                    </form>
                </div>
          	</div>
	    </div>        
  	</div>
</div>

<!-- Create Team Name Modal -->
<div class="modal fade custommdoel" id="creatnewteam" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Create New Team</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="teamdfrm">
                    <p>Enter a new team name below, click away to go back to the previous screen.</p>
                    <div>
                        <label class="text-blue-800 font-semibold">Team Name</label>
                        <input type="text" class="shadow-sm rounded-lg shadow-input " [(ngModel)]="teamAddFrom.title" (ngModelChange)="checkValidationTeamName()" [ngClass]="{'is-invalid': form_team_name_submit && form_team_name_invalid}" placeholder="E.g. Team Doe">
                        <div class="invalid-feedback" *ngIf="form_team_name_submit && form_team_name_invalid && form_team_name_message">{{form_team_name_message}}</div>
                    </div>
                </div>
            </div>
.
            <div class="modal-footer">
                <button type="button" class="btn closebtn" data-dismiss="modal">Close</button>
                <button (click)="assignTeamUserModal()" class="btn bg-blue-800">Add members to team</button>
            </div>
        </div>
    </div>

    <div class="sideclose">
        <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
        </svg> 
        <span class="text-sm">(Esc)</span>
    </div>
</div>
<!-- Create Team Name Modal -->

<!-- Assign Team User Modal -->
<div class="modal fade custommdoel" id="addteammembers" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add members to the team</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="teamdfrm">
                    <p>{{allTeamUsersData.length ? "Find existing users for the team." : "No member found!"}}</p>
                    <div *ngIf="allTeamUsersData.length">
                        <input type="text" class="shadow-sm  rounded-lg shadow-input" placeholder="Search members" [(ngModel)]="searchKeyword" (ngModelChange)="searchByKeyword()">
                        <ul class="list">
                            <li *ngFor="let teamUser of teamUsersData; let u=index;" [ngClass]="{'active': findSelectedTeamUser(teamUser._id)}" (click)="selectTeamUser(u)">{{teamUser.email}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn closebtn" data-dismiss="modal">Close</button>
                <button type="button" class="btn bg-blue-800" (click)="saveTeam()" [disabled]="!allTeamUsersData.length">Create Team</button>
            </div>
        </div>
    </div>

    <div class="sideclose">
        <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
        </svg> 
        <span class="text-sm">(Esc)</span>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="limitExceedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Limit Exceed</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="limitExceedMessage"></div>
      <div class="modal-footer popup-btns">
        <button type="button" class="submit-btn cancel" data-dismiss="modal">Cancel</button>
        <a [routerLink]="['/upgradeplan/tasks'+task_id]" class="submit-btn">Upgrade</a>
      </div>
    </div>
  </div>
</div>