<!--banner start-->
<div class="banner-section inner-banner-section">


</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
	<div class="container">
		<div class="dashborad-panel">
			<app-businesssidemenu></app-businesssidemenu>

			<div class="dashborad-rightsider">
				<div class="text-right mb-2">
					<a [routerLink]="" (click)="exportData('excel')" class="btn blue-custom-btn mr-2"> <i class="fa fa-download"></i> Excel </a>
					<a [routerLink]="" (click)="exportData('csv')" class="btn blue-custom-btn"> <i class="fa fa-download"></i> CSV </a>
				</div>
				<div class="edit-profile-set view-team-manager-page" *ngIf="taskData" id="dataTableView">
					<div class="list-top-attribute">
						<h3> View Assigned Tasks </h3>
						<div class="rightattributepanel">
							<div class="attributesearch">
								<input type="text" class="form-control" placeholder="Search task data"
									[(ngModel)]="searchKeyword" (keyup.enter)="searchByKeyword()">
								<img src="assets/images/black-search.png" alt="img">
							</div>
							<a [routerLink]="['/business-user/tasks']" class="btn blue-custom-btn backbtn"> <i class="fa fa-long-arrow-left "> </i> Back </a>
							<button class="btn blue-custom-btn backbtn" (click)="fullScreen()"> <i class="fa fa-arrows-alt"></i></button>
						</div>
					</div>

					<div class="table-data" >
						<div class="table-responsive">
							<table class="table strikeTable">
								<thead>
									<tr>
										<th>Status</th>
										<th>Assigned Team Users</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th *ngFor="let task_data of taskData.task.task_header;let i=index;">
											{{task_data}}</th>
									</tr>
								</thead>
								<tbody>
									<ng-container *ngIf="taskData && taskData.task_details.result.length">
										<tr [class]="{ deleted: task_data.task_status == 'deleted', added: task_data.task_status != 'deleted' && task_data.added_by_id}"
												*ngFor="let task_data of taskData.task_details.result;let t=index;">
											<td *ngIf="task_data.task_status !== 'deleted'"  class="recordInfotooltip" ><span>{{task_data.task_status | titlecase}}</span>
												<div class="recordInfo-badge"  *ngIf="task_data.added_by_id"><span  tooltip [tooltipRef]="addDelData" class="badge badge-pill badge-success">Added</span></div>
											</td>
											<td  class="recordInfotooltip"  *ngIf="task_data.task_status == 'deleted'">&nbsp;
												<div class="recordInfo-badge"><span  tooltip [tooltipRef]="addDelData" class="badge badge-pill badge-danger">Deleted</span></div>
											</td>
											<ng-template #addDelData>
												<!-- <div class="d-flex" *ngIf="findUser(task_data.deleted_by_id) as delRec"><p>Deleted By: {{delRec.user?.full_name}}</p></div> -->
												<div class="card" >
													<div class="card-body">
														<ng-container *ngIf="task_data.added_by_id && findUser(task_data.added_by_id) as addRec">
															<div class="row">
																<div class="col" style="color: #378541;"><strong>Added By:</strong></div>
																<div>{{addRec.user?.full_name}}</div>
															</div>
															<div class="row" *ngIf="addRec.added_reason">
																<div class="col" style="color: #378541;"><strong>Reason given:</strong></div>
																<div>{{addRec.added_reason}}</div>
															</div>
														</ng-container>
														<ng-container *ngIf="task_data.deleted_by_id && findUser(task_data.deleted_by_id) as delRec">
															<div class="row" >
																<div class="col" style="color: #9c041e;" ><strong>Deleted By:</strong></div>
																<div>{{delRec.user?.full_name}}</div>
															</div>
															<div class="row" *ngIf="delRec.deleted_reason">
																<div class="col" style="color: #9c041e;"><strong>Reason given:</strong></div>
																<div>{{delRec.deleted_reason}}</div>
															</div>
														</ng-container>
													</div>
												  </div>
											</ng-template>
											<td>
												<ng-container *ngIf="taskData.task.uncategorized_type =='reassign' && task_data.task_status != 'completed' && task_data.task_status != 'deleted'">
													<select class="form-control without-append-input" (change)='onUserChange($event, task_data._id, t)'>
														<option value="">Select user</option>
														<option *ngFor="let taskUsers of taskUsersData;let u=index;" value="{{taskUsers.user._id}}" [selected]="task_data.assign_user && taskUsers.user._id == task_data.assign_user._id">{{taskUsers.user.email}}</option>
													</select>
												</ng-container>

												<ng-container *ngIf="(taskData.task.uncategorized_type !='reassign' || task_data.task_status == 'completed') && task_data.task_status != 'deleted'">
													{{task_data.assign_user ? task_data.assign_user.email: "-"}}
												</ng-container>
											</td>
											<td class="text-center">{{task_data.start_date ? (task_data.start_date | date: "dd MMM yyyy") : "-"}}</td>
                                          	<td class="text-center">{{task_data.end_date ? (task_data.end_date.split('T')[0] | date:"dd MMM yyyy"): "-"}}</td>
											<!-- <td>{{task_data.assign_user ? task_data.assign_user.name: "-"}}</td> -->
											<td *ngFor="let data of task_data.task_modified_data;let i=index;"><span class="name-user">{{data}}</span></td>
										</tr>
									</ng-container>

									<ng-container *ngIf="!(taskData && taskData.task_details.result.length)">
										<tr>
											<td colspan="500" class="text-center">No record found!</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
						<div class="table-show-pagination">
							<div class="show-item" style="margin-bottom: 2%;">
								<span>Showing</span>
								<select class="form-control" id="pagelimit" [(ngModel)]="perPageLimit" (change)="onPageLimitChange($event)">
                                	<option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
								</select>
								<span> records out of
									{{count}} total</span>
							</div>
							<ul class="pagination" style="overflow: auto;">
								<li class="page-item {{!prevPage?'disabled':''}}">
									<a [routerLink]="" class="page-link pageprev" (click)="gotoPrevious()">Prev</a>
								</li>

								<ul class="pagination" style="overflow: auto;border: none;">
									<li *ngFor="let page of pageArray;let i=index;" class="page-item">
										<a [routerLink]="" class="page-link {{activePage==page ? 'active' : ''}}"
											(click)="gotoPage(i+1)">{{page}}</a>
									</li>
								</ul>

								<li class="page-item {{!nextPage?'disabled':''}}">
									<a [routerLink]="" class="page-link pagenext" (click)="gotoNext()">Next</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>