<!--banner start-->
  <div class="banner-section inner-banner-section">
     
    
    
  </div>
  <!--banner end-->
  
  <!--login page-->
<div class="inner-section loginver1-page">
    <div class="container">
        <div class="my-12">
            <div class="shadow-xl rounded-xl innershape">
                <div class="bg-blue-700 text-white striop rounded-tl-xl rounded-tr-xl">Change password</div>
                <div class="py-4">
                <form [formGroup]="resetPassForm" (ngSubmit)="resetPassword()">
                    <div class="px-4">
                      <div class="form-group">
                        <label>Password</label>
                        <input type="password" placeholder="Password" class="form-control shadow-sm" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password is required</div>
                          <div *ngIf="f.password.errors.pattern">Password should have at least 8 characters with one small letter, upper letter, a special character and a number</div>
                        </div> 
                      </div>

                      <div class="form-group">
                        <label>Confirm Password</label>
                        <input type="password" placeholder="Confirm password" class="form-control shadow-sm" formControlName="repassword" [ngClass]="{ 'is-invalid': submitted && f.repassword.errors }" />
                        <div *ngIf="submitted && f.repassword.errors" class="invalid-feedback">
                          <div *ngIf="f.repassword.errors.required">Password is required</div>
                          <div *ngIf="f.repassword.errors.pattern">Password should have at least 8 characters with one small letter, upper letter, a special character and a number</div>
                          <div *ngIf="f.repassword.errors.mustMatch">Password and confirm password should be the same</div>
                        </div> 
                      </div>
                    </div>
                    
                    <div class="btntoper">
                        <button class="shadow-xl mt-3 bg-blue-700">Update</button>
                      </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="banner-section credentials-panel-new">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-5">
        <figure class="login-attributes">
          <img src="assets/images/login-attributes.png" alt="typing"/>
        </figure>
      </div>
      <div class="col-md-7">
        <div class="credentials-panel">
          <div class="crendentails-right-section">
            <div class="page-heading text-center">
              <img src="assets/images/heading-object.png" alt="heading-object">
              <h2>Change password </h2>
            </div>
            <form [formGroup]="resetPassForm" (ngSubmit)="resetPassword()">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group mb-4">
                      <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">            
                          <input type="password" class="form-control" placeholder="Password" formControlName="password">
                           <img src="assets/images/password.png" alt="password"/>
                      </div>
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password is required</div>
                          <div *ngIf="f.password.errors.minlength">Password must be at least {{f.password.errors.minlength.requiredLength}} characters</div>
                      </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group mb-4">
                    <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.repassword.errors }">            
                      <input type="password" class="form-control" placeholder="Confirm password" formControlName="repassword">
                       <img src="assets/images/password.png" alt="password"/>
                    </div>
                    <div *ngIf="submitted && f.repassword.errors" class="invalid-feedback">
                      <div *ngIf="f.repassword.errors.required">Confirm Password is required</div>
                      <div *ngIf="f.repassword.errors.minlength">Confirm Password must be at least {{f.repassword.errors.minlength.requiredLength}} characters</div>
                      <div *ngIf="f.repassword.errors.mustMatch">Password and confirm password should be the same</div>
                    </div>
                  </div>
                </div>
                  
                <div class="col-sm-12">
                  <div class="form-group text-center">
                    <button type="submit" class="btn submit-btn">
                      Update
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->