import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../services/HttpClientService';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public totalTeams = 0;
  public totalTeamUsers = 0;
  public totalTasks = 0;
  public totalPendingTasks = 0;
  public totalCompletedTasks = 0;
  public totalUnassignedTasks = 0;
  public average_time:string = "0 Min(s)";
  public min_date = new Date();
  public dashboardDataSync:any;
  public tasksData:any = [];
  public teamsData: any = [];
  public dashboardAnalyticsForm: FormGroup;

  constructor(public authService: AuthService,public httpClientService: HttpClientService, private route: ActivatedRoute, private fb: FormBuilder) {
    this.dashboardAnalyticsForm = fb.group({
      task_id: [""],
      team_id: [""]
    });

  
    var stripe_callback = route.snapshot.params.stripe_callback;
    var stripe_session_id = route.snapshot.params.stripe_session_id;
    if(stripe_callback == "success" && stripe_session_id) {
      this.subscriptionAddCheckout(stripe_session_id);
    }
  }

  ngOnInit(): void {
    let self = this;
    setTimeout(function() {
      self.getTasks();
    }, 200);

    // this.dashboardDataSync = setInterval(function() {
    //   self.getInfo(false);
    // }, 10000);
  }

  ngOnDestroy(): void {
    // clearInterval(this.dashboardDataSync);
  }

  getTasks() {
    var self = this;
    this.httpClientService.showLoader = true;
    this.httpClientService.get("tasks/running").subscribe(function(res:any){
      self.httpClientService.showLoader = false;
      if (!res.error) {
        self.tasksData = res.data;
      }
      self.getTeams();
    }
    , error => {
      console.log(error);
      self.httpClientService.showLoader = false;
      self.httpClientService.showError(self.httpClientService.errorMessage);
    });
  }

  getTeams() {
    var self = this;
    this.httpClientService.showLoader = true;
    this.httpClientService.get("teams/all").subscribe(function(res:any){
      self.httpClientService.showLoader = false;
      if (!res.error) {
        self.teamsData = res.data;
      }
      self.getInfo();
    }
    , error => {
      console.log(error);
      self.httpClientService.showLoader = false;
      self.httpClientService.showError(self.httpClientService.errorMessage);
    });
  }

  getInfo(flag = true) {
    var self = this;
    this.httpClientService.showLoader = true;
    this.httpClientService.post("dashboard/data", this.dashboardAnalyticsForm.value).subscribe(async function(res:any){
      self.httpClientService.showLoader = false;
      if (!res.error) {
        self.totalTeams = res.data.totalTeams;
        self.totalTasks = res.data.totalTasks;
        self.totalPendingTasks = res.data.totalPendingTasks;
        self.totalCompletedTasks = res.data.totalCompletedTasks;
        self.totalUnassignedTasks = res.data.totalUnassignedTasks;
        self.totalTeamUsers = res.data.totalTeamUsers;
        self.average_time = self.httpClientService.getAverageTime(res.data.average_time);
      }
      else {
        self.httpClientService.showLoader = false;
      }
      //if(flag) self.dashboardCompletedRecordBusinessUser();
    }
    , error => {
      console.log(error);
      self.httpClientService.showLoader = false;
      self.httpClientService.showError(self.httpClientService.errorMessage);
    });
  }

  
  subscriptionAddCheckout(stripe_session_id) {
    var self = this;
    this.httpClientService.showLoader = true;
    this.httpClientService.post("user-subscriptions/addcheckout", {stripe_session_id: stripe_session_id}).subscribe(function(res:any){
      self.httpClientService.showLoader = false;
      if (!res.error) {
        self.httpClientService.showSuccess("Subscription successfully added in your account.");
        self.httpClientService.goTo("dashboard");
      }
      else {
        self.httpClientService.showError(res.message);
      }
    }
    , error => {
      console.log(error);
      self.httpClientService.showLoader = false;
      self.httpClientService.showError(self.httpClientService.errorMessage);
    });
  }

  

}
