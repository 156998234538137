<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--banner start-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
  	<div class="container">
	    <div class="dashborad-panel">
  
  			<h4>Please wait...</h4>
  		</div>
  	</div>
</div>
<!--banner end-->