<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
  	<div class="container">
	      <div class="dashborad-panel">
	        <!-- <app-businesssidemenu></app-businesssidemenu> -->

	         <div class="dashborad-rightsider full-width" *ngIf="taskData">
                <div class="edit-profile-set add-teamuserlist-page">
                  <div class="d-flex justify-content-start recentupdate mb-2">
                    <a [routerLink]="['/tasks']" class="btn blue-custom-btn backbtn mr-2 ml-0"><i class="fa fa-long-arrow-left"></i>&nbsp;Back</a>
                    <h1>Record Update</h1>
                    <div class="show-item ml-auto">
                      <input class="mr-0" [value]="activePage" (keyup.enter)="viewPage($event)">
                      <span>of {{count}}</span>
                    </div>
                      <div class="form-view-concept">
                        <ul class="d-flex backnextarrow ">
                          <li class="{{!prevPage?'disabled':''}}">
                            <a [routerLink]="" (click)="gotoPage(1)" title="First Record" class="first-record"> <i class="fa fa-long-arrow-left"></i> </a>
                          </li>
                          <li class="{{!prevPage?'disabled':''}}">
                            <a [routerLink]="" (click)="gotoPrevious()" title="Back Record"> <i class="fa fa-long-arrow-left"></i> </a>
                          </li>
                          <li class="{{!nextPage?'disabled':''}}">
                            <a [routerLink]="" (click)="gotoNext()" title="Forword Record"> <i class="fa fa-long-arrow-right"></i> </a>
                          </li>
                          <li class="{{!nextPage?'disabled':''}}">
                            <a [routerLink]="" (click)="gotoPage(count)" title="Last Record" class="last-record"> <i class="fa fa-long-arrow-right"></i> </a>
                          </li>
                        </ul>
                      </div>
                      <div class="d-flex mobileseacrhpnl">
                        <div class="attributesearch mr-0">
                          <input type="text" class="form-control" placeholder="Search task data" [(ngModel)]="searchKeyword" (keyup.enter)="searchByKeyword()">
                          <img src="assets/images/black-search.png" alt="img">
                        </div>
                      </div>
                  </div>
                     <!-- <div class="list-top-attribute">
                        <h3> View Assigned Task Detail </h3>
                        <div class="rightattributepanel">
                        	
                           <a [routerLink]="['/tasks/view/'+taskData.task._id]" class="btn blue-custom-btn backbtn"> <i class="fa fa-long-arrow-left"></i> Back </a>
                        </div>
                     </div> -->
                     <div class="viewmanagercontent" *ngIf="taskData.task_details">
                        <div class="datasetnames d-flex align-items-center">
                            <span>Dataset Name: <strong> {{taskData.task.file_name}} </strong> </span>
                            <span>Date of issue: <strong> {{taskData.task_details.result[0].start_date ? (taskData.task_details.result[0].start_date | date: "dd MMM yyyy") : "-"}} </strong> </span>
                            <a class="ml-auto bg-gray-300 border" [routerLink]="['./add']">Add New</a>
                            <a class="bg-gray-300 border" data-toggle="modal" data-target="#deleteConfirm">Delete</a>
                        </div>
                        <form [formGroup]="taskDetailForm" (ngSubmit)="updateTaskDetail()" style="padding-top: 0">  
                          <div class="row">
                          	<ng-container *ngFor="let dataControls of t.controls;let i=index;">
	                          	<div class="col-lg-6" *ngIf="taskData.task_permissions.permissions[i]!='NO ACCESS'">
	                            	<div class="addlableinformation">
	                                	<label class="custom-label">{{taskData.task.task_header[i]}} </label>
	                                    <div class="form-group" [formGroup]="dataControls">
	                                        <input type="text" class="form-control without-append-input" placeholder="{{taskData.task.task_header[i]}}" formControlName="value_data" [ngClass]="{ 'is-invalid': submitted && dataControls.controls.value_data.errors }" [readonly]="taskData.task_permissions.permissions[i]!='EDIT' || taskData.task_details.result[0].task_status!='assigned' ? true : false">
	                                        <div *ngIf="submitted && dataControls.controls.value_data.errors" class="invalid-feedback">
	                                          <div *ngIf="dataControls.controls.value_data.errors.required">{{taskData.task.task_header[i]}} is required</div>
	                                          <div *ngIf="dataControls.controls.value_data.errors.maxlength">{{taskData.task.task_header[i]}} should be max {{dataControls.controls.value_data.errors.maxlength.requiredLength}} characters</div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
                            </ng-container>
                          </div>

                          
                        </form>
                          <div class="reasigntask-completed d-flex align-items-center">
                            <div class="reasigntask-completed-user ml-1" *ngIf="taskData.task.reallocate_task && taskData.task_details.result[0].task_status=='assigned'">
                              <select class="form-control" (change)='onUserChange($event)'>
                                <option>Reassign Task</option>
                                <option *ngFor="let taskUsers of taskUsersData;let u=index;" value="{{taskUsers.user._id}}">{{taskUsers.user.email}}</option>
                              </select>
                            </div>

                            <div class="reasigntask-completed-btn d-flex justify-content-center ml-auto ">
                              <a [routerLink]="['/tasks']"  class="bg-gray-300" *ngIf="taskData.task_details.result[0].task_status=='assigned'">Cancel</a>
                              <a [routerLink]="" (click)="updateTaskDetail()" class="bg-blue-800 ml-3" *ngIf="taskData.task_details.result[0].task_status=='assigned'">Save</a>
                              <a [routerLink]="" (click)="updateTaskDetail(true)" class="bg-blue-800 ml-3" *ngIf="taskData.task_details.result[0].task_status=='assigned'">Complete</a>  
                            </div>
                          </div>
                     </div>
                    <div class="text-center" *ngIf="!taskData.task_details">No record found</div> 
                </div>
            </div>
	      </div>        
  	</div>
</div>

<!-- Modal -->
<div class="modal fade" id="deleteConfirm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" role="alert">
          The current record will be deleted!
        </div>
        <p class="text-center">Are you sure you want to do this?</p>
        <p><input class="w-100" #reason type="text" placeholder="Add reason for deletion"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger"  data-dismiss="modal" (click)="updateTaskDetail(false, true, reason)">Delete Record</button>
      </div>
    </div>
  </div>
</div>