<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
  <div class="inner-section object-comman object-comman-reverse after-login-section">
      <div class="container">
              <div class="dashborad-panel">
                <app-businesssidemenu></app-businesssidemenu>

                  <div class="dashborad-rightsider">
                    <div class="d-sm-flex justify-content-between dropdownpanelright">
                      <h3>
                        Dashboard
                      </h3>
                      <form class="no-padding" [formGroup]="dashboardAnalyticsForm" (ngSubmit)="getInfo(false)">
                        <ul class="d-flex">
                          <li>
                            <div class="form-group">
                              <select class="form-control" formControlName="task_id" (change)="getInfo(false)">
                                <option value="">All Datasets</option>
                                <option *ngFor="let taskData of tasksData;let t=index;" value="{{taskData._id}}">{{taskData.file_name}}</option>
                                </select>
                            </div>
                          </li>
                          <li>
                            <div class="form-group">
                              <select class="form-control" formControlName="team_id" (change)="getInfo(false)">
                                <option value="">All Teams</option>
                                <option *ngFor="let teamData of teamsData;let te=index;" value="{{teamData._id}}">{{teamData.title}}</option>
                              </select>
                            </div>
                          </li>
                        </ul>
                      </form>
                    </div>
                       
                    <div class="row mb-3">
                              <!-- <div class="col-lg-4">
                                <div class="couting-dashboard">
                                    <div>
                                    <img src="assets/images/team.png" alt="team"/>
                                      <span>Total teams</span>
                                      <strong>{{totalTeams}}</strong>
                                    </div>
                                  </div>
                              </div>

                              <div class="col-lg-4">
                                <div class="couting-dashboard">
                                    <div>
                                    <img src="assets/images/team-members.png" alt="team"/>
                                      <span>Total team members</span>
                                      <strong>{{totalTeamUsers}}</strong>
                                    </div>
                                  </div>
                              </div> -->

                              <div class="col-lg-6">
                                <div class="couting-dashboard">
                                    <div>
                                    <img src="assets/images/total-task.png" alt="task"/>
                                      <span>Total records allocated</span>
                                      <strong>{{totalTasks}}</strong>
                                    </div>
                                  </div>
                              </div>

                              <div class="col-lg-6">
                                <div class="couting-dashboard">
                                     <div>
                                    <img src="assets/images/record.png" alt="pending-task"/>
                                       <span>Records awaiting review</span>
                                       <strong>{{totalPendingTasks}}</strong>
                                     </div>
                                  </div>
                              </div>

                              
                          </div>
                          <div class="row">
                              <div class="col-lg-6">
                                <div class="couting-dashboard">
                                      <div>
                                    <img src="assets/images/completed-record.png" alt="completed-task"/>
                                       <span>Records completed</span>
                                       <strong>{{totalCompletedTasks}}</strong>
                                       </div>
                                  </div>
                              </div>

                              <div class="col-lg-6">
                                <div class="couting-dashboard">
                                      <div>
                                    <img src="assets/images/uncategories-record.png" alt="completed-task"/>
                                       <span>Uncategorised records</span>
                                       <strong>{{totalUnassignedTasks}}</strong>
                                       </div>
                                  </div>
                              </div>

                              <!-- <div class="col-lg-4">
                                <div class="couting-dashboard">
                                      <div>
                                    <img src="assets/images/average-time.png" alt="completed-task"/>
                                       <span>Average Time</span>
                                       <strong>{{average_time}}</strong>
                                       </div>
                                  </div>
                              </div> -->
                        </div>
                  </div>
              </div>        
      </div>
  </div>