import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs/Observable';
import { HttpClientService } from '../../services/HttpClientService';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user$: Observable<any>;
  constructor(private authService: AuthService, public httpClientService: HttpClientService) { }

  ngOnInit(): void {
  	this.user$ = this.authService.loggedIn.asObservable();
  }

  onLogout(){
    this.authService.logout();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

}
