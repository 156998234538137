<!--banner start-->
<div class="banner-section inner-banner-section">


</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
  	<div class="container">
	      <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>

	          <div class="dashborad-rightsider">

                 <div class="edit-profile-set manager-teamuserlist-page">
                     <div class="list-top-attribute">
                        <h3> Manage users </h3>
                        <div class="rightattributepanel" >
                        	<div class="attributesearch" style="width:auto !important;">
                            	<input type="text" class="form-control" placeholder="Search users name" [(ngModel)]="searchKeyword" (ngModelChange)="searchByKeyword()">
                                <img src="assets/images/black-search.png" alt="img">
                            </div>
                            <a [routerLink]="['/teamusers/add']" class="btn blue-custom-btn">+ Add User</a>&nbsp;
                            <button data-toggle="modal" data-target="#excelUpload" class="btn blue-custom-btn">Bulk Import Users</button>
                        </div>
                     </div>
                     <div class="table-data">
                         <div class="table-responsive">
                         	<table class="table">
                            	<thead>
                                   <tr>
                                	    <th>Profile Picture</th>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th class="text-center">User Teams</th>
                                      <th>Last Logged In</th>
                                      <th class="text-center">Status</th>
                                      <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngIf="teamuUsersData && teamuUsersData.result.length">
                                  	<tr *ngFor="let teamUserData of teamuUsersData.result;let i=index;">
                                      	<td><figure class="thumb"><img [src]="teamUserData.profile_picture ? httpClientService.imageUrl + teamUserData.profile_picture : 'assets/images/no-img.png'"></figure></td>
                                        <td><span class="name-user">{{teamUserData.full_name}}</span></td>
                                        <td><span class="name-user">{{teamUserData.email}}</span></td>
                                        <td class="text-center"><span class="name-user">{{teamUserData.user_teams.length}}</span></td>
                                        <td>{{teamUserData.loggedin_at | date: httpClientService.defaltDate}}</td>
                                        <td class="text-center"><img [src]="'assets/images/'+(teamUserData.status ? 'green' : 'red')+'-status.png'" alt="img"></td>
                                        <td>
                                        	<div class="action">
                                        		<a [routerLink]="['/teamusers/view/'+teamUserData._id]"><img src="assets/images/view.png" alt="img"></a>
                                        		<a [routerLink]="" (click)="deleteTeamUser(i)"><img src="assets/images/delete.png" alt="img"></a>
                                        	</div>
                                        </td>
                                      </tr>
                                    </ng-container>

                                    <ng-container *ngIf="!(teamuUsersData && teamuUsersData.result.length)">
                                      <tr>
                                        <td colspan="7" class="text-center">No record found!</td>
                                      </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                         </div>
                         <div class="table-show-pagination">
                          <div class="show-item">
                              <span>Page {{activePage}} of {{pageCount}}, showing {{currentCount}} records out of {{count}} total</span>
                            </div>
                            <ul class="pagination">
                              <li class="page-item {{!prevPage?'disabled':''}}">
                                <a [routerLink]="" class="page-link pageprev" (click)="gotoPrevious()">Prev</a>
                              </li>

                              <li *ngFor="let page of pageArray;let i=index;" class="page-item">
                                <a [routerLink]="" class="page-link {{activePage==page ? 'active' : ''}}" (click)="gotoPage(i+1)">{{page}}</a>
                              </li>

                              <li class="page-item {{!nextPage?'disabled':''}}">
                                <a [routerLink]="" class="page-link pagenext" (click)="gotoNext()">Next</a>
                              </li>
                            </ul>
                         </div>
                     </div>

                </div>
            </div>
	      </div>
  	</div>
</div>

<!-- Modal -->
  <div class="modal fade" id="excelUpload" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header bg-blue-700">
          <h4 class="modal-title dash-menu-heading">Add Bulk User Import </h4>
        </div>
        <div class="modal-body">
			<form  enctype="multipart/form-data">
				<div class="profile-change-section text-center">
					<h1><i class="fa fa-upload fa-5 btn-default"></i></h1>

					<div class="change-profile-photo">
						<input type="file" ng2FileSelect [uploader]="uploader">
						<span>Upload Bulk User Import File </span>
					</div>
				</div>

			</form>
					<br>
			<div class="form-group text-center">
          <a href="assets/docs/Sample.xlsx" class="btn btn-default" download >Download bulk user import template</a>
		  </div>
        </div>
        <div class="modal-footer">

          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>

    </div>
  </div>

<!-- Modal -->
<div class="modal fade" id="limitExceedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Limit Exceed</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="limitExceedMessage"></div>
      <div class="modal-footer popup-btns">
        <button type="button" class="submit-btn cancel" data-dismiss="modal">Cancel</button>
        <a [routerLink]="['/upgradeplan']" class="submit-btn" >Upgrade</a>
      </div>
    </div>
  </div>
</div>
