<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section mvh dashborad-inner uploadpage">
  	<div class="container">
	      <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>
	        <div class="dashborad-rightsider">
            <div id="content">
              <h1>Upload dataset</h1>
              <p>Drag and drop your file below or click to select and upload your file from your computer, currently supported are files of type CSV, XLS and XLSX.</p>              
              <div class="processing-status" *ngIf="processingStatus$  else upload">
                <div  *ngIf="processingStatus$ | async as processingStatus">
                  <div *ngIf="processingStatus.error; else processMsg" >
                    <h3 class="text-warn">Error processing - {{processingStatus.error?.message}}</h3><br/>
                    <a (click)="processingStatus$ = null">Retry</a>
                  </div>
                  <ng-template #processMsg>  <h3>Upload completed. <br/><br/>{{processingStatus.message || "Processing, please wait..."}}</h3><br/>
                    <mat-progress-bar *ngIf="!processingStatus.error" mode="indeterminate"  ></mat-progress-bar>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
	      </div>        
  	</div>
</div>

<ng-template #upload>
  <app-fileUpload [options]="uploadOpts" class="mt-12 rounded-xl" (result$)="onUploadComplete($event)"></app-fileUpload>  
</ng-template>

<!-- Modal -->
<div class="modal fade" id="limitExceedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Limit Exceed</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="limitExceedMessage"></div>
      <div class="modal-footer popup-btns">
        <button type="button" class="submit-btn cancel" data-dismiss="modal">Cancel</button>
        <a [routerLink]="['/upgradeplan']" class="submit-btn">Upgrade</a>
      </div>
    </div>
  </div>
</div>
