import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/HttpClientService';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseClass } from '../../services/BaseClass'; 
import { UploadxOptions, Tus, UploadState } from 'ngx-uploadx';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { switchMap, retry, share, takeUntil, map, tap } from 'rxjs/operators';

class Ufile {
	name!: string;
	uploadId!: string;
	progress!: number;
	status!: string;
  
	constructor(state: UploadState) {
	  this.uploadId = state.uploadId;
	  this.update(state);
	}
  
	update(state: UploadState): void {
	  this.name = state.name;
	  this.progress = state.progress;
	  this.status = state.status;
	}
  }

interface ITaskAddResponse {
	_id: string;
	name: string;
	limitExceed?: boolean;
	total_rows?: number;
	total_columns?: number;
	task_header: string[];
	bytesWritten: number;
	status: string;
	size: number;
	file_name: string;
	progress: number;
	error?: {message: string };
	message?: string;
	originalName: string;
}


declare var $: any;
@Component({
  selector: 'app-taskadd',
  templateUrl: './taskadd.component.html',
  styleUrls: ['./taskadd.component.css']
})
export class BusinessTaskaddComponent extends BaseClass implements OnDestroy {
	submitted:boolean = false;
	taskForm: FormGroup;
	uploadOpts: UploadxOptions;
	progress = 0;
	isUploading = false;
	processingStatus$: Observable<ITaskAddResponse>;  
	private stopPolling = new Subject();
	private sub: Subscription;
	
	constructor(public httpClientService: HttpClientService, private fb: FormBuilder, private auth: AuthService) {
		super();
		const headers = new Map<string, string>(); 
		const user: {_id: string} | undefined = auth.loggedIn.value as any;
		this.uploadOpts = {
			endpoint: httpClientService.serviceBase+'tasks/addupdate',
			withCredentials: true,
			metadata: {
				userId: user && user._id || null
			},
			uploaderClass: Tus
		};
		//console.log(this.uploadOpts);
	}

	ngOnDestroy(): void {
		this.stopPolling.next();
		if (this.sub ) this.sub.unsubscribe();
	}
	async onUploadComplete(state: UploadState) {
		const { url } = state;
		console.log(`Upload Complete: ${JSON.stringify(state)}`);
		if (!url || !state.responseStatus) {
			return this.httpClientService.showError("Upload failed - unknown error");
		}
		this.processingStatus$ = timer(1, 10000).pipe(
			// the url is absolute, we need a relative url so remove "serviceBase"
			switchMap(() => this.httpClientService.get(url.replace(this.httpClientService.serviceBase, ''))),
			retry(),
			tap((res) => console.log(res)),
			map((res) => res as any as ITaskAddResponse),
			share(),
			takeUntil(this.stopPolling)
		);
		this.sub = this.processingStatus$.subscribe((data) => {
			console.log(`data: ${JSON.stringify(data)}`);
			const { _id } = data;
			if (!data || data.error) {
				const msg = data && data.error && data.message || "Internal error";
				this.httpClientService.showError(`Upload Failed - ${msg}`);
				this.stopPolling.next(true);
				this.sub.unsubscribe();
				setTimeout(() => this.processingStatus$ = null, 3000);
			} else if (_id) {
				if (data.limitExceed) {
					this.httpClientService.goTo('tasks/limitexceeded/'+_id);
				} else {
					this.httpClientService.goTo("tasks/doesheader/"+_id);
				}
			}
		});
	}
	
  	onSuccessFunction(response) {
	    this.taskForm.patchValue({
	    	profile_picture: response.data
    	});
    	this.saveTask();
    	console.log(response.data); // this gives filename after successful upload
	}
  
  	get f() { return this.taskForm.controls; }

  	saveTask() {
		var self = this;
		this.submitted = true;
		if (this.taskForm.valid) {
			this.httpClientService.showLoader = true;
			var sendRequestData = this.taskForm.value;
			this.httpClientService.post("tasks/addupdate", sendRequestData).subscribe(function(res:any){
				self.httpClientService.showLoader = false;
				if (!res.error) {
					// self.httpClientService.showSuccess(res.message);
					let id = res.data._id;
					if (res.limitExceed) {
						self.httpClientService.goTo('tasks/limitexceeded/'+id);
					}
					else {
						// window.location.href = "#/tasks/doesheader/"+id;
						self.httpClientService.goTo("tasks/doesheader/"+id);
					}
					// self.httpClientService.goTo('tasks/limitexceeded/'+id);
					// if(confirm("Does this include header?")) {
						// self.updateTaskHeader(res.data._id)
					 //  }else{
						// self.httpClientService.goTo('business-user/tasks-header/edit/'+id);
					 //  }
				}
				else {
					// if (res.data) {
						// $("#limitExceedMessage").text(res.message);
						// $("#limitExceedModal").modal('show');
						self.httpClientService.goTo('tasks/limitexceeded/'+res.data._id);
					// }
					// else {
					// 	self.httpClientService.showError(res.message);
					// }
				}
			}
			, error => {
				self.httpClientService.showLoader = false;
				self.httpClientService.showError(self.httpClientService.errorMessage);
			});
		}
	}

	updateTaskHeader(task_id){
		var self = this;
		this.httpClientService.get("taskdetails/delete/" + task_id).subscribe(function(res:any){
			if (!res.error) {
				// self.httpClientService.showSuccess(res.message);
				self.httpClientService.goTo('jobs/rule/'+task_id);
			}
			else {
				self.httpClientService.showError(res.message);
			}
		}
		, error => {
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}

}
