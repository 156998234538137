<!--banner start-->
<div class="banner-section inner-banner-section">


</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
    <div class="container">
            <div class="dashborad-panel">
                <app-businesssidemenu *ngIf="userData.role=='business_user'"></app-businesssidemenu>
                <div class="dashborad-rightsider" [ngClass]="{'full-width': userData.role=='team_user'}">
                     
                    <div class="edit-profile-set">
                        <h3> Change Password </h3>
                            
                        <form [formGroup]="changePasswordForm" (ngSubmit)="saveChangePassword()">
                            <div class="row">
                                <div class="col-sm-12">
                                    <span class="form-heading">Fill your password</span>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group mb-4">
                                        <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }">            
                                            <input type="password" class="form-control" placeholder="Current password" formControlName="old_password">
                                            <img src="assets/images/password.png" alt="password">
                                        </div>
                                        <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                                            <div *ngIf="f.old_password.errors.required">Current Password is required</div>
                      						<div *ngIf="f.old_password.errors.pattern">Password should have at least 8 characters with one small letter, upper letter, a special character and a number</div>
                                        </div>  
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group mb-4">
                                        <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">            
                                            <input type="password" class="form-control" placeholder="Password" formControlName="password">
                                            <img src="assets/images/password.png" alt="password">
                                        </div>
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
											<div *ngIf="f.password.errors.required">Password is required</div>
											<div *ngIf="f.password.errors.pattern">Password should have at least 8 characters with one small letter, upper letter, a special character and a number</div>
										</div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group mb-4">
                                        <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.repassword.errors }">            
                                            <input type="password" class="form-control" placeholder="Confirm password" formControlName="repassword">
                                            <img src="assets/images/password.png" alt="password">
                                        </div>
                                        <div *ngIf="submitted && f.repassword.errors" class="invalid-feedback">
											<div *ngIf="f.repassword.errors.required">Confirm Password is required</div>
											<div *ngIf="f.repassword.errors.pattern">Password should have at least 8 characters with one small letter, upper letter, a special character and a number</div>
											<div *ngIf="f.repassword.errors.mustMatch">Password and confirm password should be the same</div>
										</div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-12">
                                    <div class="form-group text-center">
                                        <div class="btn submit-btn">
                                           <input type="submit" value="Change Password"> 
                                           <i class="fa fa-angle-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>        
    </div>
</div>