import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, DomPortal, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  tooltip: HTMLElement;
  @Input("tooltip") tooltipTitle: string;
  @Input("tooltipRef") tooltipRef: TemplateRef<unknown>;

  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private renderer: Renderer2,
              private overlayPositionBuilder: OverlayPositionBuilder,
              private elementRef: ElementRef, private vcr: ViewContainerRef) {
  }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -12,
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    if (this.tooltipTitle) {
      const tooltip = this.renderer.createElement("div"); 
      tooltip.appendChild(this.renderer.createElement("div")); 
      this.renderer.appendChild(tooltip, this.renderer.createText(this.tooltipTitle));
      this.renderer.appendChild(document.body, tooltip); 
      const ref = this.overlayRef.attach(new DomPortal(tooltip));
    } else if ( this.tooltipRef) {
      const ref = this.overlayRef.attach(new TemplatePortal(this.tooltipRef, this.vcr));
    }
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }
}
