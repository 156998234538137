<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
	<div class="container">
		<div class="dashborad-panel">
			<app-businesssidemenu></app-businesssidemenu>

			<div class="dashborad-rightsider">
				<div class="edit-profile-set add-teamuserlist-page">
                     <div class="list-top-attribute">
                        <h3> Purchase Plan </h3>
                        <div class="rightattributepanel">
                        	
                           <a [routerLink]="['/upgradeplan']" class="btn blue-custom-btn backbtn"> <i class="fa fa-long-arrow-left"></i> Back </a>
                        </div>
                     </div>
                     <div class="viewmanagercontent">
                        <form [formGroup]="checkoutForm" (ngSubmit)="buy()">
                          <div class="row">
                          	<div class="col-lg-12">
                            	<div class="addlableinformation">
                                	<label class="custom-label">Card Detail </label>
                                    <div class="form-group">
			                    		<div id="card-element" class="append-icon field" style="padding: 0 0 0 .75rem;">
			                    			<ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
			                    		</div>
			                    	</div>
                                </div>
                            </div>
                            
                            <div class="col-sm-12">
                                  <div class="form-group text-center">
                                    <div class="btn submit-btn">
                                       <input type="submit" value="Pay Now"> 
                                       <i class="fa fa-angle-right"></i>
                                    </div>
                                   </div>
                            </div>
                            
                          </div>
                        </form>
                     </div>
                        
                </div>
			</div>
		</div>
	</div>
</div>