import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/HttpClientService';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-doesheader',
  templateUrl: './doesheader.component.html',
  styleUrls: ['./doesheader.component.css']
})
export class DoesheaderComponent implements OnInit {
	public task_id: string;
	public taskData: any;
	public taskStepData:any;
	public columnHeaderData:any;
	public total_rows:number=0;
	public total_columns_array:any = [];

	public inEditMode: boolean = false;
	public stepWorkflow: any = [
		{ "step_name": "does_header", "page": "doesheader" },
		{ "step_name": "assign_datatype", "page": "assigndatatype" },
		{ "step_name": "teams_manage", "page": "allocateteam" },
		{ "step_name": "conflict_permission", "page": "conflictcolumnpermissions" },
		{ "step_name": "reallocate_task", "page": "reallocatetasks" },
		{ "step_name": "business_rules", "page": "configurebusinessrules" },
		{ "step_name": "uncategorized_result_divide", "page": "uncategorizedresulttoteams" },
		{ "step_name": "start_date", "page": "setstartdate" },
		{ "step_name": "end_date", "page": "setcompletedate" },
		{ "step_name": "assignment_policy", "page": "userassignmentpolicy" }

	];



	constructor(private route: ActivatedRoute, public httpClientService: HttpClientService) {
		this.task_id = route.snapshot.params.task_id;
		this.inEditMode = (route.snapshot.url[3] && route.snapshot.url[3].path == "edit");
	}

	ngOnInit(): void {
		let self = this;
		setTimeout(function () {
			self.fetchTaskStep(); 
		}, 200);
	}

	getStepIndex(x) {
		return x.step_name == this;
	}

	getNextPage(currentPage) {
		return this.stepWorkflow[this.stepWorkflow.findIndex(this.getStepIndex, currentPage) + 1]
	}

	checkForLatestStep() {
		var self = this;
		this.httpClientService.showLoader = true;
		this.httpClientService.get("tasks/fetchtaskstep/all/" + self.task_id).subscribe(function (res: any) {

			var latestStep = res.taskStepdata[0];
			var goTo = "";

			if (latestStep.step_complete) {

				if (latestStep.type == "end_date") {
					goTo = latestStep.boolean_question ? self.getNextPage(latestStep.type).page : "jobsummary";
				}
				else {
					goTo = self.getNextPage(latestStep.type).page;
				}
				
			}
			else {
				goTo = self.stepWorkflow.find(element => element.step_name == latestStep.type).page;
			}	

			self.httpClientService.goTo("tasks/" + goTo + "/"+self.task_id);

			

		}, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});

	}


	getTasks() {
		var self = this;
		this.httpClientService.showLoader = true;
		if (this.inEditMode) this.checkForLatestStep();

		
		this.httpClientService.get("tasks/view/" + this.task_id + "?page=1&perPage=11").subscribe(function (res: any) {
			self.httpClientService.showLoader = false;
			if (!res.error) {
				self.taskData = res.data;
				
				self.total_rows = self.taskData.task_details.count;
				self.total_columns_array = Array(self.taskData.task.total_columns).fill(0).map((x,i)=>i);
				
				// If column header data isn't present, then populate the object
				// Basically the first row will already have been identified as the potential colum headers
				// so just need to map/format the array into something that the API expects when saving the task step
				// Also, remove said row from the actual data collection
				if (!self.columnHeaderData) {
					self.columnHeaderData = [];

					self.columnHeaderData = self.taskData.task.task_header.map(x => {
						let rObj = {}
   						rObj["column_name"] = x
   						return rObj
					});
					self.taskData.task_details.result.splice(0, 1);
							
				}
				
			}
			else {
				self.httpClientService.showError(res.message);
			}
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}

	fetchTaskStep() {
		var self = this;
		this.httpClientService.showLoader = true;
		var requestData = {
			task_id: this.task_id,
			type: "does_header"
		}
		this.httpClientService.post("tasks/fetchtaskstep", requestData).subscribe(function (res: any) {
			self.httpClientService.showLoader = false;
			if (!res.error) {
				self.taskStepData = res.data;
				self.columnHeaderData = res.data.data;
			}
			self.getTasks();
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}

	gotoYes() {
		var goTo = "tasks/assigndatatype/"+this.task_id;
		var taskStepRequestData = {
			task_id: this.task_id,
			type: "does_header",
			boolean_question: true,
			data: [],
			step_complete: true
		}
		var missingHeader = false;
		for (var i = 0; i < this.taskData.task.task_header.length; ++i) {
			if (typeof this.taskData.task.task_header[i] == "undefined" || !this.taskData.task.task_header[i]) {
				missingHeader = true;
			}
		}
		
		if (missingHeader) {
			goTo = "tasks/assignheader/"+this.task_id;
			if (this.taskStepData) {
				if (this.taskStepData.boolean_question) {
					taskStepRequestData.data = this.taskStepData.data;
					taskStepRequestData.step_complete = this.taskStepData.step_complete;
				}
				else {
					taskStepRequestData.step_complete = false;
				}
			}
			else {
				taskStepRequestData.step_complete = false;
			}

			alert("One or more header column(s) were found to be blank. Please specify values for these column(s).");
		}
	

		for (var i = 0; i < this.total_columns_array.length; ++i) {
			if (this.taskData.task.task_header[i]) {
				
				taskStepRequestData.data.push({
					column_name: this.taskData.task.task_header[i]
				});
			}
			else {
				taskStepRequestData.data.push({
					column_name: ""
				});
			}
		}
	
		this.saveTaskStep(taskStepRequestData, goTo);
	}

	gotoNo() {
		var goTo = "tasks/assignheader/"+this.task_id;
		var taskStepRequestData = {
			task_id: this.task_id,
			type: "does_header",
			boolean_question: false,
			data: null,
			step_complete: false
		}
		if (this.taskStepData) {
			if (this.taskStepData.boolean_question) {
				taskStepRequestData.data = null;
				taskStepRequestData.step_complete = false;
			}
			else {
				taskStepRequestData.data = this.taskStepData.data;
				taskStepRequestData.step_complete = this.taskStepData.step_complete;
			}
		}
		this.saveTaskStep(taskStepRequestData, goTo);
	}

	saveTaskStep(taskStepRequestData, goTo) {
		var self = this;
		this.httpClientService.showLoader = true;
		this.httpClientService.post("tasks/createtaskstep", taskStepRequestData).subscribe(function (res: any) {
			self.httpClientService.showLoader = false;
			if (!res.error) {
				self.httpClientService.goTo(goTo);
			}
			else {
				self.httpClientService.showError(res.message);
			}
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}
}