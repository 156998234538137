import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Uploader, UploadState, UploadxOptions, UploadxService } from 'ngx-uploadx';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-fileUpload',
    templateUrl: './fileUpload.component.html',
    styleUrls: [ './fileUpload.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class FileUploadComponent implements OnInit, OnDestroy { 
      @Input() options: UploadxOptions;
      @Output() result$: Observable<UploadState>;
      state$: Observable<UploadState>;
      uploads$: Observable<Uploader[]>; 
      constructor(private uploadService: UploadxService) {
        this.result$ = this.uploadService.events.pipe(
          filter((state) => state.status === 'complete'),
          take(1)
        )
      }
      ngOnInit(): void {
        this.uploads$ = this.uploadService.connect(this.options);
        this.state$ = this.uploadService.events;
      }

      ngOnDestroy(): void {
        this.uploadService.disconnect();
      }

      cancelAll(): void {
        this.uploadService.control({ action: 'cancel' });
      }

      pauseAll(): void {
        this.uploadService.control({ action: 'pause' });
      }

      uploadAll(): void {
        this.uploadService.control({ action: 'upload' });
      }
  }