<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
  <div class="container">
    <div class="dashborad-panel">
      <div class="dashborad-rightsider full-width">
        <div class="edit-profile-set add-teamuserlist-page">
            <div class="d-flex justify-content-start recentupdate mb-2">
                <h1>Add New Record</h1>
            </div>
            <div class="viewmanagercontent" *ngIf="taskData && taskData.task_details">
                    <div class="datasetnames d-flex align-items-center">
                        <span>Dataset Name: <strong> {{taskData.task.file_name}} </strong> </span>
                        <span>Date of issue: <strong> {{taskData.task_details.result[0].start_date ? (taskData.task_details.result[0].start_date | date: "dd MMM yyyy") : "-"}} </strong> </span>
                    </div>
                <form [formGroup]="taskDetailForm" style="padding-top: 0">  
                        <div class="row">
                            <ng-container *ngFor="let dataControls of t.controls;let i=index;">
                                <div class="col-lg-6" *ngIf="taskData.task_permissions.permissions[i]!='NO ACCESS'">
                                <div class="addlableinformation">
                                    <label class="custom-label">{{taskData.task.task_header[i]}} </label>
                                    <div class="form-group" [formGroup]="dataControls">
                                        <input type="text" class="form-control without-append-input" placeholder="{{taskData.task.task_header[i]}}" 
                                            formControlName="value_data" [ngClass]="{ 'is-invalid': submitted && dataControls.controls.value_data.errors }" 
                                        >
                                        <div *ngIf="submitted && dataControls.controls.value_data.errors" class="invalid-feedback">
                                            <div *ngIf="dataControls.controls.value_data.errors.required">{{taskData.task.task_header[i]}} is required</div>
                                            <div *ngIf="dataControls.controls.value_data.errors.maxlength">{{taskData.task.task_header[i]}} should be max {{dataControls.controls.value_data.errors.maxlength.requiredLength}} characters</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        </div>
                </form>
                <div class="reasigntask-completed d-flex align-items-center justify-content-end">
                    <a [routerLink]="['..']"  class="bg-gray-300" >Cancel</a>
                    <a [routerLink]="" (click)="saveNewTaskDetail(taskDetailForm.value)" class="bg-blue-800 ml-3" >Save Draft</a>
                </div>
            </div>
      </div>
    </div>
  </div>
</div>
