import {Injectable, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import * as moment from 'moment';
import  { environment } from "../../environments/environment";

@Injectable()
export class HttpClientService {
  public serviceBase:string = environment.API_URL;
  public imageUrl:string =environment.IMAGE_URL;
  public defaltDate:string = "dd MMM yyyy, h:mm aaa";
  public excelDateFormat:string = "DD/MM/YYYY";
  public device_type:string = "a";
  public device_id:string;
  public errorMessage:string = "Oops! Something went wrong!";
  public userData:any = null;
  public showLoader:boolean = false;
  public progressLoadingShow:boolean = false;  
  public progressLoadingValue:number = 0;
  public hashURLActive:string = "";
  public settings:any = null;
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) {
    this.getSettings();
  }

  async getHomePages(){
    try {
      const res: any = await this.get("pages/home").toPromise();
      if (!res.error) return res.data;
    } catch (err) {
      console.log(err.message);
    }
    return null;
  }

  getSettings(){
    var self = this;
    this.get("setting/view").subscribe(function(res:any){
      if (!res.settings) {
        self.settings = res.data;
      }
    }
    , error => {
    });
  }

  downloadFile(url, data) {
    
    var httpOptions = { headers: new HttpHeaders()};
    httpOptions.headers.append("Content-Type", "application/json");
   
    return this.http.post(this.serviceBase + url, data, {
      responseType: "blob",
      headers: httpOptions.headers,
      observe: "response"
    });
  }

  

  

  async getPlans(){
    try {
      const res: any = await this.get("plans").toPromise();
      if (!res.error) return res.data;
    } catch (err) {
      console.log(err.message);
    }
    return null;
  }

  showSuccess(message) {
    this.toastr.success(message);
  }

  showError(message) {
    this.toastr.error(message);
  }

  showInfo(message) {
    this.toastr.info(message);
  }

  showWarning(message) {
    this.toastr.warning(message);
  }
  
  get(url, httpOptions?) {
    const options = httpOptions || { withCredentials: true };
      return this.http.get(this.serviceBase+url, { ...options, responseType: 'json'});
  }
  post(url, data, httpOptions?) {
    const options = httpOptions || { withCredentials: true };
    return this.http.post<Object>(this.serviceBase+url, data,  { ...options, responseType: 'json'});
  }
  delete(url, httpOptions?) {
    const options = httpOptions || { withCredentials: true };
    return this.http.delete<Object>(this.serviceBase+url, { ...options, responseType: 'json'});
  }
  getAverageTime(average_time_minute) {
    var minutes = Math.floor(average_time_minute);
    var hours = Math.floor(minutes/60);
    var days = Math.floor(hours/24);
    // hours = hours-(days*24);
    // minutes = minutes-(days*24*60)-(hours*60);
    minutes = minutes-(hours*60);

    var return_data = (hours ? hours+" Hr(s) " : "") + minutes+" Min(s)";
    return return_data;
  }

  setDataAccording(data) {
    var converted_date_value = moment(data).format(this.excelDateFormat);
    if (typeof data == "string" && converted_date_value != "Invalid date") {
      data = converted_date_value;
    }
    return data;
  }

  goTo(page) {
    this.router.navigate([page]);
  }

  parseInt(num) {
    return parseInt(num);
  }

  numberToLetter(n) {
      var ordA = 'a'.charCodeAt(0);
      var ordZ = 'z'.charCodeAt(0);
      var len = ordZ - ordA + 1;
    
      var s = "";
      while(n >= 0) {
          s = String.fromCharCode(n % len + ordA) + s;
          n = Math.floor(n / len) - 1;
      }
      return s.toUpperCase();
  }
}