import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/HttpClientService';
import { PaginationClass } from '../../services/PaginationClass';

declare var $:any;

@Component({
	selector: 'app-tasklist',
	templateUrl: './tasklist.component.html',
	styleUrls: ['./tasklist.component.css']
})
export class BusinessTasklistComponent extends PaginationClass implements OnInit {
	public tasksData: any;
	public searchKeyword: string = "";
	public currentCount: number = 0;
	
	constructor(public httpClientService: HttpClientService) {
		super();
	}

	ngOnInit(): void {
		let self = this;
		setTimeout(function () {
			self.getTasks();
		}, 200);
	}

	searchByKeyword() {
		this.activePage = 1;
		this.gotoFirst();
	}

	hitApi() {
		this.getTasks();
	}

	getTasks() {
		var self = this;
		this.httpClientService.showLoader = true;
		this.httpClientService.get("datasets" + this.pageUrl + "&q=" + this.searchKeyword).subscribe(function (res: any) {
			self.httpClientService.showLoader = false;

			if (!res.data) {
				self.tasksData = null;
			}
			else {
				self.setPagination({ count: res.data.count, perPage: res.data.perPage });
			}
			
			
			if (!res.error) {
				self.tasksData = res.data.result;
				console.log(self.tasksData); 
				self.currentCount = res.data.count;
			}
			else {
				self.currentCount = 0;
				self.tasksData = null;
			}
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}

	

	updateUnuncategorizedType(event, index) {
		var self = this;
		if (!confirm("You can't revert back after you confirm. Are you sure?")) {
			return false;
		}
		var requestData = {
			task_id: this.tasksData[index]._id,
			uncategorized_type: event.target.value
		}
		this.httpClientService.showLoader = true;
		console.log("requestData", requestData);
		this.httpClientService.post("tasks/updateuncategorizedtype", requestData).subscribe(function (res: any) {
			self.httpClientService.showLoader = false;
			if (!res.error) {
				self.tasksData[index].uncategorized_type = requestData.uncategorized_type;
			}
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
		
	}


	deleteTask(id) {
		var message = "You are deleting this dataset and any tasks issued to users will be removed related to this dataset if you have already configured the dataset and issued tasks to users. Once you delete this dataset it will no longer be possible to retrieve the data, please ensure you have exported the data before continuing. Do you wish to delete this dataset?";
		if (confirm(message)) {
			var self = this;
			this.httpClientService.showLoader = true;
			this.httpClientService.get("tasks/delete/"+id).subscribe(function(res:any){
				self.httpClientService.showLoader = false;
				if (!res.error) {
					self.httpClientService.showSuccess(res.message);
					
					self.getTasks();
					 
				}
				else {
					self.httpClientService.showError(res.message);
				}
			}
			, error => {
				self.httpClientService.showLoader = false;
				self.httpClientService.showError(self.httpClientService.errorMessage);
			});
		}
	} 

}
