import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/HttpClientService';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';

declare var $:any;
@Component({
  selector: 'app-teamview',
  templateUrl: './teamview.component.html',
  styleUrls: ['./teamview.component.css']
})

export class TeamviewComponent implements OnInit {
	public team_id:string;
	public teamData:any;
	
	
	public showLoader:boolean = true;
	public progressLoadingValue:number = 0;
	public uploader: FileUploader;
	public fileUploadDataObject: any;
	public serviceBase:any;
	public showMessage:boolean = true;
	
	constructor(private route: ActivatedRoute, public httpClientService: HttpClientService) {
		this.team_id = route.snapshot.params.id;
	}

	ngOnInit(): void {
		let self = this;
		setTimeout(function() {
			self.getTeam();
		}, 200);
		
		var userData = JSON.parse(localStorage.getItem("user"));
		
		var fileUploadDataObject = {
	      url: this.httpClientService.serviceBase + 'excelUpload',
	      itemAlias: "excelFile",
	      additionalParameter: {"team_id": this.team_id},
	    };
	    this.uploader = new FileUploader(fileUploadDataObject);
	    this.uploadNow(this.uploader, this.httpClientService, ["xls","xlsx","csv"]);
	}

	
	uploadNow(uploader,httpClient, allowedExtensions=[], options = {showLoader:true, showMessage: false}){
    if(options && typeof options.showLoader != "undefined") this.showLoader = options.showLoader;
    if(options && typeof options.showMessage != "undefined") this.showMessage = options.showMessage;
    var self = this;
	
    uploader.onAfterAddingFile = (fileItem) => {
      fileItem.withCredentials = true;
      if (allowedExtensions.length) {
        if (this.checkFileExpension(fileItem.file.name, allowedExtensions)) {
          if(self.showLoader) httpClient.showLoader = true;
          uploader.uploadAll();
        }
        else {
          httpClient.showError("You have uploaded an invalid file type. Allowed file types are "+allowedExtensions.toString());
        }
      }
      else {
        if(self.showLoader) httpClient.showLoader = true;
        uploader.uploadAll();
      }
    };
    uploader.onProgressAll = (progress: any) => {
      httpClient.progressLoadingValue = progress;
    }
    uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      if(this.showLoader) httpClient.showLoader = false;
    };

    uploader.onSuccessItem = (item:any, response:any, status:any, headers:any) => {
		
      if(this.showLoader) httpClient.showLoader = false;
      response = JSON.parse(response);
		if (response.data) {
			console.log(response.message +'=== '+'File structure not proper');
			if(response.message === 'File structure not proper'){
				httpClient.showError("You have uploaded an invalid Structure File");
       		}else{
				$("#limitExceedMessage").text(response.message);
				$("#limitExceedModal").modal('show');
			}
			$('#excelUpload').modal('toggle');
		}else{
			httpClient.showSuccess(response.message);
			$('#excelUpload').modal('toggle');
			location.reload(); 
		}
		
		 self.getTeam();
    
    };
    
    uploader.onErrorItem = (item:any, response:any, status:any, headers:any) => {
      if(this.showLoader) httpClient.showLoader = false;

      httpClient.showError(response.message);
    };
  }

 checkFileExpension(fileName, allowedExtensions) : any {
    var fileExtension = fileName.split('.').pop();
	console.log(fileExtension);
    if(this.isInArray(allowedExtensions, fileExtension)) {
      return true;
    } else {
      return false;
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  
 


	getTeam() {
		var self = this;
		this.httpClientService.showLoader = true;
		this.httpClientService.get("teams/view/"+this.team_id).subscribe(function(res:any){
			console.log("res", res);
			self.httpClientService.showLoader = false;
			if (!res.error) {
				self.teamData = res.data;
			}
			else {
				self.httpClientService.showError(res.message);
			}
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}

	

}
