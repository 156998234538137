<!--banner start-->
<div class="banner-section inner-banner-section">


</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
  	<div class="container">
	      <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>

	        <div class="dashborad-rightsider">

	            <div class="edit-profile-set view-team-manager-page" *ngIf="teamData">
	                 <div class="list-top-attribute">
	                    <h3> View team </h3>
	                    <div class="rightattributepanel">

	                        <a [routerLink]="['/teams/edit/'+teamData.team._id]" class="btn blue-custom-btn"> + Edit team </a>
	                        <a [routerLink]="['/teams']" class="btn blue-custom-btn backbtn"> <i class="fa fa-long-arrow-left"></i> Back </a>
	                    </div>
	                 </div>

	                <div class="viewmanagercontent">
	                      <div class="row">
	                      	<div class="col-lg-6">
	                        	<div class="viewlableinformation">
	                            	<label class="custom-label">Team name </label>
	                                <span>{{teamData.team.title}}</span>
	                            </div>
	                        </div>
	                        <div class="col-lg-6">
	                        	<div class="viewlableinformation">
	                            	<label class="custom-label">Created at </label>
	                                <span>{{teamData.team.created_at | date: httpClientService.defaltDate}}</span>
	                            </div>
	                        </div>
	                        <div class="col-lg-12">
	                        	<div class="viewlableinformation">
	                            	<label class="custom-label">Description</label>
	                                <span>{{teamData.team.description}}</span>
	                            </div>
	                        </div>

	                        <div class="col-lg-12">
	                        	<div class="viewlableinformation">
	                            	<label class="custom-label">Status</label>
	                                <span class="statusview {{teamData.team.status ? 'green' : 'red'}}"> <i class="fa fa-circle"></i> {{teamData.team.status ? 'Active' : 'Deactive'}}</span>
	                            </div>
	                        </div>

	                      </div>
	                </div>

	                <div class="list-top-attribute">
	                    <h3> Team Users </h3>
						<div class="rightattributepanel">
						<button data-toggle="modal" data-target="#excelUpload" class="btn blue-custom-btn">Bulk Import</button>
						</div>
	                </div>
	                <div class="table-data">
                        <div class="table-responsive">
                         	<table class="table">
                            	<thead>
                                   	<tr>
                                	  <th>Name</th>
                                      <th>Email</th>
                                      <th>Mobile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  	<ng-container *ngIf="teamData && teamData.team_users.length">
	                                  	<tr *ngFor="let userData of teamData.team_users;let i=index;">
	                                        <td><span class="name-user"><a  [routerLink]="['/teamusers/view/'+userData.user_id._id]">{{userData.user_id.full_name}}</a></span></td>
	                                        <td><span class="name-user">{{userData.user_id.email}}</span></td>
	                                        <td><span class="name-user">{{userData.user_id.mobile}}</span></td>
	                                    </tr>
                                    </ng-container>

                                    <ng-container *ngIf="!(teamData && teamData.team_users.length)">
										<tr>
											<td colspan="4" class="text-center">No record found!</td>
										</tr>
                                    </ng-container>
                                </tbody>
                            </table>
                         </div>
                    </div>
	            </div>
	        </div>
	      </div>
  	</div>
</div>


<!-- Modal -->
  <div class="modal fade" id="excelUpload" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header bg-blue-700">
          <h4 class="modal-title dash-menu-heading">Add Bulk Import </h4>
        </div>
        <div class="modal-body">
			<form  enctype="multipart/form-data">
				<div class="profile-change-section text-center">
					<h1><i class="fa fa-upload fa-5 btn-default"></i></h1>

					<div class="change-profile-photo">
						<input type="file" ng2FileSelect [uploader]="uploader">
						<span>Upload Bulk Import File </span>
					</div>
				</div>

			</form>
					<br>
			<div class="form-group text-center">
          <a href="assets/docs/Sample.xlsx" class="btn btn-default" download >Download bulk import template</a>
		  </div>
        </div>
        <div class="modal-footer">

          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>

    </div>
  </div>


<!-- Modal -->
<div class="modal fade" id="limitExceedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Limit Exceed</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="limitExceedMessage"></div>
      <div class="modal-footer popup-btns">
        <button type="button" class="submit-btn cancel" data-dismiss="modal">Cancel</button>
        <a [routerLink]="['/upgradeplan']" class="submit-btn" >Upgrade</a>
      </div>
    </div>
  </div>
</div>
