import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/HttpClientService';
import { PaginationClass } from '../../services/PaginationClass';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';

declare var $:any;

@Component({
  selector: 'app-teamuserlist',
  templateUrl: './teamuserlist.component.html',
  styleUrls: ['./teamuserlist.component.css']
})
export class TeamuserlistComponent extends PaginationClass  implements OnInit {
 	public teamuUsersData: any;
	public searchKeyword:string = "";
	public showLoader:boolean = true;
	public progressLoadingValue:number = 0;
	public uploader: FileUploader;
	public fileUploadDataObject: any;
	public serviceBase:any;
	public showMessage:boolean = true;
	public currentCount:number = 0;
	
	constructor(public httpClientService: HttpClientService) {
		super();
		
	}

	ngOnInit(): void {
		let self = this;
		setTimeout(function() {
			self.getTeamUsers();
		}, 200);
		
		var userData = JSON.parse(localStorage.getItem("user"));
		
		var fileUploadDataObject = {
	      url: this.httpClientService.serviceBase + 'excelUpload',
	      itemAlias: "excelFile",
	      //additionalParameter: {"name": "kapil"},
	    };
	    this.uploader = new FileUploader(fileUploadDataObject);
	    this.uploadNow(this.uploader, this.httpClientService, ["xls","xlsx","csv"]);
	}

	searchByKeyword() {
		this.activePage = 1;
		this.gotoFirst();
	}

	hitApi(){
	    this.getTeamUsers();
	}
	
	
	uploadNow(uploader,httpClient, allowedExtensions=[], options = {showLoader:true, showMessage: false}){
    if(options && typeof options.showLoader != "undefined") this.showLoader = options.showLoader;
    if(options && typeof options.showMessage != "undefined") this.showMessage = options.showMessage;
    var self = this;
	
    uploader.onAfterAddingFile = (fileItem) => {
      fileItem.withCredentials = true;
      if (allowedExtensions.length) {
        if (this.checkFileExpension(fileItem.file.name, allowedExtensions)) {
          if(self.showLoader) httpClient.showLoader = true;
          uploader.uploadAll();
        }
        else {
          httpClient.showError("You have uploaded an invalid file type. Allowed file types are "+allowedExtensions.toString());
        }
      }
      else {
        if(self.showLoader) httpClient.showLoader = true;
        uploader.uploadAll();
      }
    };
    uploader.onProgressAll = (progress: any) => {
      httpClient.progressLoadingValue = progress;
    }
    uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
      if(this.showLoader) httpClient.showLoader = false;
    };

    uploader.onSuccessItem = (item:any, response:any, status:any, headers:any) => {
		
      if(this.showLoader) httpClient.showLoader = false;
      response = JSON.parse(response);
		if (response.data) {
			console.log(response.message +'=== '+'File structure not proper');
			if(response.message === 'File structure not proper'){
				httpClient.showError("You have uploaded an invalid Structure File");
       		}else{
				$("#limitExceedMessage").text(response.message);
				$("#limitExceedModal").modal('show');
			}
			$('#excelUpload').modal('toggle');
		}else{
			httpClient.showError(response.message);
			$('#excelUpload').modal('toggle');
//			location.reload(); 
		}
		 self.getTeamUsers();
		
     //this.onSuccessFunction(response, true);
    };
    
    uploader.onErrorItem = (item:any, response:any, status:any, headers:any) => {
      if(this.showLoader) httpClient.showLoader = false;

      httpClient.showError(response.message);
    };
  }

 checkFileExpension(fileName, allowedExtensions) : any {
    var fileExtension = fileName.split('.').pop();
	console.log(fileExtension);
    if(this.isInArray(allowedExtensions, fileExtension)) {
      return true;
    } else {
      return false;
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  
  

	getTeamUsers() {
		var self = this;
		this.httpClientService.showLoader = true;
		this.httpClientService.get("teamusers"+this.pageUrl+"&q="+this.searchKeyword).subscribe(function(res:any){
			console.log("res", res);
			self.httpClientService.showLoader = false;
			self.setPagination({count: res.data.count, perPage: res.data.perPage});
			if (!res.error) {
				self.teamuUsersData = res.data;
				self.currentCount = res.data.result.length;
			}
			else {
				self.teamuUsersData = null;
				self.currentCount = 0;
			}
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}

	deleteTeamUser(index) {
		if (confirm("Are you sure you want to delete this record?")) {
			var self = this;
			this.httpClientService.showLoader = true;
			this.httpClientService.get("teamusers/delete/"+this.teamuUsersData.result[index]._id).subscribe(function(res:any){
				self.httpClientService.showLoader = false;
				if (!res.error) {
					self.teamuUsersData.result.splice(index, 1);
					self.httpClientService.showSuccess(res.message);
				}
				else {
					self.httpClientService.showError(res.message);
				}
			}
			, error => {
				self.httpClientService.showLoader = false;
				self.httpClientService.showError(self.httpClientService.errorMessage);
			});
		}
	} 

	
}
