import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Rx';
import { HttpClientService } from '../services/HttpClientService';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,  public  httpClientService: HttpClientService, private location: Location) {

  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot)  {
      if (await this.authService.isLoggedIn()) {
        
   
        if (this.authService.loggedIn.value['role'] == 'business_user' && this.authService.loggedIn.value['plan_name'] == '') {

          if (!document.documentURI.includes('auth-payment/')) {
            this.router.navigate(['/selectplan']);
          }
        }

        return true;
      } else {
        this.router.navigate(['/login']); 
        return false; 
      }
  }
}
