import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/services/HttpClientService';
import { TaskviewformComponent } from '../taskviewform/taskviewform.component';

@Component({
  templateUrl: './task-add-record.component.html',
  styleUrls: ['./task-add-record.component.css']
})
export class TaskAddRecordComponent  extends TaskviewformComponent implements OnInit {

  constructor(public router: Router, public httpClientService: HttpClientService, public fb: FormBuilder, public activeRoute: ActivatedRoute) {
		super(router, httpClientService, fb, activeRoute);
  }

	ngOnInit(): void { 
    setTimeout(async () => {
      await this.getTaskDetail();
			this.setPagination({ count: 0, perPage: 1 });
      this.taskDetailForm.reset();
    }, 200);
	}
  async saveNewTaskDetail(data) {
    const newRec = {
		task_id: this.task_id,
		task_data: data.task_modified_data
	}
		try {
			const res = await this.httpClientService.post("/tasks/data/insert", newRec).toPromise() as any;
			console.log(res);
			if (!res || res.error) throw new Error(res && res.error || "Invalid response");
			//this.gotoPage(this.count + 1);
			this.router.navigate(["../"], {relativeTo: this.activeRoute, skipLocationChange: true});
		} catch (err) {
			console.log(err);
			this.httpClientService.showError(this.httpClientService.errorMessage);
		}
  }

	get f() { return this.taskDetailForm.controls; }
	get t() { return this.f.task_modified_data as FormArray; }

}
