<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section mvh dashborad-inner uploadpage">
  	<div class="container">
	    <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>
	        <div class="dashborad-rightsider">
	            <div id="content" class="text-center">
	                <img class="mx-auto mb-8 inforicon" src="assets/images/exclamation-circle-duotone.svg" alt="img">
	                <h1>Thanks for your payment!</h1>
	                <p>We have processed your payment and your plan has been updated. Please click on Continue.</p>
	                <div class="mt-16 d-flex justify-content-center">
	                   <a [routerLink]="" (click)="goContinue()" class="bg-blue-800">Continue</a>
	                </div>
	            </div>
          	</div>
	    </div>        
  	</div>
</div>
