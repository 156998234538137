<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section mvh dashborad-inner uploadpage">
  	<div class="container">
	    <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>
	        <div class="dashborad-rightsider">
	            <div id="content">
                    <h1 *ngIf="total_rows !== 0">We found {{total_rows}} rows</h1>
                    <h3 class="mt-16 mb-6 opacity-25 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/reviewmatchingdata/'+task_id]"> <b>10. </b> Review matching data </a> </h3>
                    <h3 class="mt-3 mb-6 opacity-50 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/uncategorizedresulttoteams/'+task_id]"> <b>11. </b> Would you like to assign the uncategorized results to teams to deal with? </a> </h3>
                    <h3 class="mt-3 mb-6 opacity-75 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/setstartdate/'+task_id]"> <b>12. </b> Set a start date </a> </h3>
                    <h3 class="mt-3 mb-6 commanlsittecxt"> <b>13.</b> Would you like to set a target completion date? </h3>
                    <p class="mb-4">If you select no then the records will be split equally amongst selected teams and all records assigned at once.</p>

                    <h3 *ngIf="end_date">Estimated Completion Date: {{end_date | date: 'dd MMMM yyyy'}}</h3>

                   	<div class="w-full d-sm-flex mt-16">
                   		<button class="bg-blue-800 mr-3" *ngIf="end_date" (click)="saveCompleteDate(true)">Continue</button>
                   		<mat-form-field class="example-full-width" appearance="fill" style="width:1px;visibility:hidden;height: 0;">
						  <mat-label>Choose a date</mat-label>
						  <input matInput [matDatepicker]="picker" [min]="min_date" [(ngModel)]="end_date">
						  <mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
                    	<button class="" [ngClass]="{'bg-blue-800': !end_date, 'bg-gray-300': end_date}" (click)="picker.open()">{{end_date ? "Change Date" : "Yes"}}</button>

                    	<button class="bg-gray-300 ml-3" (click)="saveCompleteDate(false)">No</button>
                    </div>
                </div>
          	</div>
	    </div>        
  	</div>
</div>