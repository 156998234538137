import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Rx';
import { HttpClientService } from '../services/HttpClientService';

@Injectable()
export class SelectPlanGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,  public  httpClientService: HttpClientService) {

  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot)  {
      if (await this.authService.isLoggedIn()) {
        
        return true;
      } else {
        this.router.navigate(['/login']); 
        return false; 
      }
  }
}
