import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/HttpClientService';
import { PaginationClass } from '../../services/PaginationClass';
import { FileUploader } from 'ng2-file-upload';

declare var $:any;

@Component({
  selector: 'app-teamlist',
  templateUrl: './teamlist.component.html',
  styleUrls: ['./teamlist.component.css']
})
export class TeamlistComponent extends PaginationClass implements OnInit {
	public teamsData: any;
	public searchKeyword:string = "";
	public currentCount:number = 0;
	public showLoader:boolean = true;
	public uploader: FileUploader;
	public showMessage:boolean = true;
	public fileUploadDataObject: any;
	constructor(public httpClientService: HttpClientService) {
		super();
	}

	ngOnInit(): void {
		let self = this;
		setTimeout(function() {
			self.getTeams();
		}, 200);
		var userData = JSON.parse(localStorage.getItem("user"));
		var fileUploadDataObject = {
			url: this.httpClientService.serviceBase + 'excelUploadTeam',
			itemAlias: "excelFile", 
			//additionalParameter: {"name": "kapil"},
		  };
		  this.uploader = new FileUploader(fileUploadDataObject);
		  this.uploadNow(this.uploader, this.httpClientService, ["xls","xlsx","csv"]);
	}

	searchByKeyword() {
		this.activePage = 1;
		this.gotoFirst();
	}

	hitApi(){
	    this.getTeams();
	}
	uploadNow(uploader,httpClient, allowedExtensions=[], options = {showLoader:true, showMessage: false}){
		if(options && typeof options.showLoader != "undefined") this.showLoader = options.showLoader;
		if(options && typeof options.showMessage != "undefined") this.showMessage = options.showMessage;
		var self = this;
		
		uploader.onAfterAddingFile = (fileItem) => {
		  fileItem.withCredentials = true;
		  if (allowedExtensions.length) {
			if (this.checkFileExpension(fileItem.file.name, allowedExtensions)) {
			  if(self.showLoader) httpClient.showLoader = true;
			  uploader.uploadAll();
			}
			else {
			  httpClient.showError("You have uploaded an invalid file type. Allowed file types are "+allowedExtensions.toString());
			}
		  }
		  else {
			if(self.showLoader) httpClient.showLoader = true;
			uploader.uploadAll();
		  }
		};
		uploader.onProgressAll = (progress: any) => {
		  httpClient.progressLoadingValue = progress;
		}
		uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
		  if(this.showLoader) httpClient.showLoader = false;
		};
	
		uploader.onSuccessItem = (item:any, response:any, status:any, headers:any) => {
			
		  if(this.showLoader) httpClient.showLoader = false;
		  response = JSON.parse(response);
			if (response.data) {
				console.log(response.message +'=== '+'File structure not proper');
				if(response.message === 'File structure not proper'){
					httpClient.showError("You have uploaded an invalid Structure File");
				   }else{
					$("#limitExceedMessage").text(response.message);
					$("#limitExceedModal").modal('show');
				}
				$('#excelUpload').modal('toggle');
			}else{
				httpClient.showSuccess(response.message);
				$('#excelUpload').modal('toggle');
				location.reload(); 
			}
			 self.getTeams();
			
		 //this.onSuccessFunction(response, true);
		};
		
		uploader.onErrorItem = (item:any, response:any, status:any, headers:any) => {
		  if(this.showLoader) httpClient.showLoader = false;
	
		  httpClient.showError(response.message);
		};
	  }
	
	 checkFileExpension(fileName, allowedExtensions) : any {
		var fileExtension = fileName.split('.').pop();
		console.log(fileExtension);
		if(this.isInArray(allowedExtensions, fileExtension)) {
		  return true;
		} else {
		  return false;
		}
	  }
	
	  isInArray(array, word) {
		return array.indexOf(word.toLowerCase()) > -1;
	  }
	  
  	getTeams() {
		var self = this;
		this.httpClientService.showLoader = true;
		this.httpClientService.get("teams"+this.pageUrl+"&q="+this.searchKeyword).subscribe(function(res:any){
			self.httpClientService.showLoader = false;
			self.setPagination({count: res.data.count, perPage: res.data.perPage});
			if (!res.error) {
				self.teamsData = res.data;
				self.currentCount = res.data.result.length;
			}
			else {
				self.currentCount = 0;
				self.teamsData = null;
			}
		}
		, error => {
			self.httpClientService.showLoader = false;
			self.httpClientService.showError(self.httpClientService.errorMessage);
		});
	}

	deleteTeam(index) {
		if (confirm("Are you sure you want to delete this record?")) {
			var self = this;
			this.httpClientService.showLoader = true;
			this.httpClientService.get("teams/delete/"+this.teamsData.result[index]._id).subscribe(function(res:any){
				self.httpClientService.showLoader = false;
				if (!res.error) {
					self.teamsData.result.splice(index, 1);
					self.httpClientService.showSuccess(res.message);
				}
				else {
					self.httpClientService.showError(res.message);
				}
			}
			, error => {
				self.httpClientService.showLoader = false;
				self.httpClientService.showError(self.httpClientService.errorMessage);
			});
		}
	}

	
}
