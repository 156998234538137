<!--banner start-->
<div class="banner-section">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="banner-content-box">
                    <h1>Task driven dataset collaboration.</h1>
                    <p>We get it, datasets with a seemingly never ending list of records to review are always the thing that gets put off.</p>
                    <a href="javascript:void(0);" (click)="scrollAbout()" class="btn custom-btn custom-solid-btn"> 
                        Learn more <i class="fa fa-angle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner-right-box">
                    <figure>
                        <div class="ripple-anim"><img src="assets/images/banner.jpg" alt="banner" class="hover-effect" /></div>
                    </figure>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>

    </div>

    <!--div class="scroll-down">
        <a [routerLink]='"."' [fragment]="'howitwork'">
            <img src="assets/images/down-scroll.png" alt="scroll-down">
            Scroll down
        </a>
    </div-->

</div>
<!--banner end-->

<!--what is cleata start-->
<div id="whatiscleata" *ngIf="homePageData">
    <!-- <div [outerHTML]="homePageData.whatiscleata.content"></div> -->
    <div class="what-is-cleata object-comman">
        <div class="container">
        <div class="page-heading text-center"><img alt="heading-object" src="assets/images/heading-object.png" />
        <h2>About that data...</h2>
        
        <p>Data when accurate, is an asset.</p>
        </div>
        
        <div class="row align-items-center">
        <div class="col-lg-7">
        <div class="trailer-video-wrapper">
        <div class="trailer-img">
        <div style="border-radius: 30px;overflow: hidden;" wfd-id="163">
        <iframe width="100%" src="https://www.youtube.com/embed/ZPq3sSir8GI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div>
        </div>
        </div>
        </div>
        
        <div class="col-lg-5">
        <div class="cleata-right"><span>Data as an asset</span>
        
        <p>Data is created and collected constantly, over time it becomes unwieldy and you know you need to put in place something to clean it up. Perhaps now is the time because of a system migration or a process change? Sure you can send the file to someone to do, but does it really ever get done? We&#39;ve heard it before &#39;too busy with day job&#39;, &#39;I&#39;ll get to it when i&#39;m free&#39; etc. Ultimately it just gets added to a to-do list that never seems to ever there.</p><p>The thing is, that data if it was reviewed and cleansed, would be an asset to you and to your organisation. Click the video to learn more.</p>
        
        <hr />
        <ul class="custom-list">
            <li>No-one has time to review large datasets</li>
            <li>Data when accurate is an asset to me</li>
            <li>Cleata is GDPR compliant</li>
            <li>Cleata can automate the management of data reviews and cleansing tasks whilst monitoring completions</li>
        </ul>
        </div>
        </div>
        </div>
        </div>
        </div>
        
</div>
<!--what is cleata end-->

<!--how it work start-->
<div id="howitwork" *ngIf="homePageData && homePageData.howitworks">
    <div [outerHTML]="homePageData.howitworks.content"></div>
</div>
<!--how it work end-->

<!--cleata feature start-->
<div id="cleatafeature" *ngIf="homePageData && homePageData.cleatafeature">
    <div [outerHTML]="homePageData.cleatafeature.content"></div>
</div>
<!--cleata feature end-->

<!---pricing start-->
<div class="pricing-section" id="pricing">
    <div class="container">
        <div class="page-heading text-center">
            <img src="assets/images/heading-object.png" alt="heading-object" />
            <h2>Our Plans</h2>
            <p>Our plans provide services ranging from entry level needs up to enterprise class provisions and everything in between.</p>
        </div>
        <div class="pricing-plan text-center">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="monthly-tab" data-toggle="tab" href="#monthlytab"
                        role="tab">Monthly</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link yeaeractive yearofferset" id="year-tab" data-toggle="tab" href="#yeartab"
                        role="tab">Yearly <span>10% off</span></a>

                </li>
 
            </ul>
            
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="monthlytab" role="tabpanel">
                    <div class="row">
                        <ng-container *ngFor="let plan of homePagePlans | async">
                            <div class="col-lg-3 col-sm-6">
                                <div class="price-plan-content hover-effect">
                                    <div class="price-plan-rate" *ngIf="plan.type!='support'">
                                        <sup>£</sup>{{plan.month_amount}} <sub>/month</sub>
                                    </div>
                                    <div class="price-plan-rate" *ngIf="plan.type=='support'">
                                        Contact Us
                                    </div>
                                    <div class="price-plan-heading">
                                        {{plan.title}}
                                    </div>
                                    <ul class="plan-list" [innerHTML]="plan.description"></ul>
                                    <div class="plan-btn">
                                        <a [routerLink]="['/signup']" class="btn custom-btn" *ngIf="plan.type!='support'">Sign up</a>
                                        <a [routerLink]="['/contactus']" class="btn custom-btn" *ngIf="plan.type=='support'">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                
                <div class="tab-pane fade" id="yeartab" role="tabpanel">
                    <div class="row">
                        <ng-container *ngFor="let plan of homePagePlans | async">
                            <div class="col-lg-3 col-sm-6">
                                <div class="price-plan-content hover-effect">
                                    <div class="price-plan-rate" *ngIf="plan.type!='support'">
                                        <sup>£</sup>{{plan.year_amount/12}} <sub>/month</sub>
                                    </div>
                                    <div class="price-plan-rate" *ngIf="plan.type=='support'">
                                        Contact Us
                                    </div>
                                    <div class="price-plan-heading">
                                        {{plan.title}}
                                    </div>
                                    <ul class="plan-list" [innerHTML]="plan.description"></ul>
                                    <div class="plan-btn">
                                        <a [routerLink]="['/signup']" class="btn custom-btn" *ngIf="plan.type!='support'">Sign up</a>
                                        <a [routerLink]="['/contactus']" class="btn custom-btn" *ngIf="plan.type=='support'">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!---pricing end --->