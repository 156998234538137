<footer >
  <div class="row mx-3 text-white justify-content-around">

    <div class="col d-flex justify-content-left">
        <p>
        Copyright Cleata Limited <br />64a Cumberland Street, Edinburgh,
        EH3 6RE
        </p>
    </div>
    <ul class="col nav d-flex justify-content-center">
        <li class="nav-item">
            <a class="nav-link" href="https://cleata.com/privacy-policy/" target="_blank">Privacy Policy</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://cleata.com/terms-and-conditions/" target="_blank">Terms and Conditions</a>
        </li>
    </ul>
    <ul class="col nav  d-flex justify-content-end">
        <li class="nav-item et-social-facebook text-center mr-2 ml-auto"><a class="icon" href="https://www.facebook.com/cleatacom"><span style="display: none;" aria-hidden="true">Follow</span></a></li>
        <li class="nav-item et-social-twitter text-center mr-2"><a class="icon" href="https://www.twitter.com/cleatacom"><span style="display: none;" aria-hidden="true">Follow</span></a></li>
        <li class="nav-item et-social-linkedin text-center mr-2"><a class="icon" href="https://www.linkedin.com/company/cleata"><span style="display: none;" aria-hidden="true">Follow</span></a></li>
    </ul>
  </div>
</footer>