<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section mvh dashborad-inner uploadpage">
  	<div class="container">
	    <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>
	        <div class="dashborad-rightsider">
	            <div id="content">
					<h1 *ngIf="total_rows !== 0">We found {{total_rows}} rows</h1>
                   	<p>Aenean ultrices eu lectus in accumsan. Ut tincidunt, arcu non porttitor vestibulum, leo massa venenatis metus, ut dignissim augue turpis vel sapien. Etiam vel pretium massa. Nullam volutpat sit amet lorem nec blandit.</p>
                   
                    <h3 class="mt-16 mb-6 opacity-25 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/doesheader/'+task_id]"> <b>1.</b> Does your first row contain headings? </a> </h3>
                    <h3 class="mt-3 mb-6 opacity-50 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/assignheader/'+task_id]"> <b>2.</b> Please assign headings for your import </a> </h3>
                    <h3 class="mt-3 mb-6 opacity-75 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/assigndatatype/'+task_id]"> <b>3.</b> Please assign data types to your columns </a> </h3>
                    <h3 class="mt-3 mb-6 commanlsittecxt"> <b>4.</b> Data Type Mismatches Detected</h3>
                    <p>Data in one or more columns does not match the datatype selected, this could be an error in your choice of datatype or a mismatch in one of the records, if you continue, the records with mismatched datatypes will be removed and seperated to be dealt with as uncategorised records. You can assign these to one or more teams to deal with at a later stage.</p>
                     
                    <div class="w-full d-sm-flex mt-16 blckbtn">
                     	<a [routerLink]="['/tasks/allocateteam/'+task_id]" class="bg-blue-800">Continue  </a>
                        <a [routerLink]="['/tasks/assigndatatype/'+task_id]" class="ml-3 bg-gray-300">Re-configure data types</a>
                    </div>
                </div>
          	</div>
	    </div>        
  	</div>
</div>