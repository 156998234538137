<nav class="navbar navbar-dark fixed-top navbar-expand-lg bg-blue-700 ">
    <a class="navbar-brand ml-3" href="#"><img src="assets/images/logo_72.png" alt="cleata logo" class="default-logo"/></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto" *ngIf="user$ | async as user" >
                  <li class="nav-item" id="home-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="user.role == 'business_user'">
                    <a class="nav-link" [routerLink]="['/dashboard']" (click)="scrollToTop()">Home </a>
                  </li>
                  <li class="nav-item" id="home-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="user.role == 'team_user'">
                    <a class="nav-link" [routerLink]="['/dashboard/teamuser']" (click)="scrollToTop()">Home </a>
                  </li>

                  <li class="nav-item" id="home-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="user.role == 'team_user'">
                    <a class="nav-link" [routerLink]="['/tasks']" (click)="scrollToTop()">Manage datasets </a>
                  </li>
                  <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a class="nav-link" href ="https://support.cleata.com" target="_blank">Support</a>
                  </li>
                  <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                    <a class="nav-link" href="https://cleata.com/contact-us">Contact us</a>
                  </li>
                  <li class="nav-item pr-1 d-flex flex-nowrap">
                      <figure class="thumb" *ngIf="user.profile_picture">
                        <img [src]="httpClientService.imageUrl + user.profile_picture" alt="profile"/>
                      </figure>
                      <a [routerLink]="['/profile']" class="name nav-link pr-0">{{user.full_name}}</a>
                      <ng-container *ngIf="user.plan_name && ['business_user','team_user'].includes(user.plan_name)">
                        <a [routerLink]="['/profile']" *ngIf="user.plan_name" class="smalltext nav-link">{{user.plan_name}}</a>  
                      </ng-container>
                      <a  id="navbarDropdown"role="button" class="btn p-0 pt-1" data-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="5.122" height="20.329" class="mx-4" viewBox="0 0 5.122 24.329"><path d="M2.561,113.6a2.561,2.561,0,1,0,2.561,2.561A2.558,2.558,0,0,0,2.561,113.6Zm2.561-7.043A2.561,2.561,0,1,1,2.561,104,2.558,2.558,0,0,1,5.122,106.561Zm0,19.207a2.561,2.561,0,1,1-2.561-2.561A2.558,2.558,0,0,1,5.122,125.768Z" transform="translate(0 -104)" fill="#fff"></path></svg>
                      </a>
                      <div class="dropdown-menu" style="left: unset; right: 10px;" aria-labelledby="navbarDropdown">
                        <a class="btn dropdown-item" [routerLink]="['/changepassword']">Change Password</a>
                        <div class="dropdown-divider"></div>
                        <a class="btn dropdown-item" (click)="onLogout()">Log out</a>
                      </div>
                  </li>
      </ul>
    </div>
</nav>