import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { User } from './user';
import { HttpClientService } from '../services/HttpClientService';

@Injectable()
export class AuthService  {
  public loggedIn = new BehaviorSubject<Object>(null);

  constructor(private router: Router, public  httpClientService: HttpClientService) {

  }
  async isLoggedIn() {
    try {
      const res = await this.httpClientService.get("user-profile").toPromise<any>();
      console.log({profile: res});
      const { data } = res;
      data.plan_name = res.plan_name;
      if (data && !data.error && data._id) {
        

          this.loggedIn.next(data);
         
        
      } else { 
        this.loggedIn.next(null);
        throw new Error(`Invalid or no session found`);
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  async login(user: User){
  	var self = this;
    if (user.email !== '' && user.password != '' ) {
    	var sendRequestData = user;
    	this.httpClientService.showLoader = true;
    	this.httpClientService.post('login', sendRequestData).subscribe(function(res:any){
  			self.httpClientService.showLoader = false;
  			if (!res.error) {
          if(user.rememberMe){
            localStorage.setItem('email', res.data.email);
            localStorage.setItem('rememberMe', JSON.stringify(user.rememberMe));
          }
          // res.data.role = user.role;
          self.httpClientService.showSuccess(res.message);
  				self.setSessionData(res.data);
  			}
  			else {
  				self.httpClientService.showError(res.message);
  			}
  		}
  		, error => {self.httpClientService.showLoader = false;self.httpClientService.showError(self.httpClientService.errorMessage);} );
    }
  }

  setSessionData(data) {
    console.log({sessionData: data});
    this.loggedIn.next(data);
    if(data.role == 'team_user'){
      this.httpClientService.goTo("dashboard/teamuser");
    }else{
      if (data.plan_name == '') {
        this.httpClientService.goTo("selectplan");
      }
      else {
        this.httpClientService.goTo("dashboard");
      }
    }
  }

  async logout() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('email');
    // localStorage.removeItem('password');
    // localStorage.removeItem('rememberMe');
    try {
      await this.httpClientService.post('logout', { }).toPromise();
      this.loggedIn.next(null);
      this.httpClientService.goTo('login');
    } catch (err) {
      console.log(err);
      this.httpClientService.showError(`Log out failed - ${err.message}`);
    }
  }
}
