<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section mvh dashborad-inner uploadpage">
  	<div class="container">
	    <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>
	        <div class="dashborad-rightsider">
	            <div id="content" *ngIf="taskData">
		            <form [formGroup]="assignHeaderForm" (ngSubmit)="saveAssignHeader()">   
	                   	<h1 *ngIf="total_rows !== 0">We found {{total_rows}} rows</h1>
	                   	<p>Please assign headings to each of the columns listed below in the text boxes, if you want to exclude a column from being processed, please enter any text for the column heading and you can remove the column on the next screen</p>
	                    <h3 class="mt-16 mb-6 opacity-75 border-b pb-4 commanlsittecxt"> <a [routerLink]="['/tasks/doesheader/'+task_id]"> <b>1.</b> Does your first row contain headings?</a> </h3>
	                    <h3 class="mt-3 mb-6 commanlsittecxt"> <b>2.</b> Please assign headings for your import </h3>
	                    <div class="w-full relative">
	                     	<div class="table-responsive">
	                            <table class="shadow-sm table">
	                            	<thead *ngIf="t.controls.length">
		                              	<tr *ngFor="let result of taskData.task_details.result | slice:0:1;let i=index;">
			                            	<th *ngFor="let total_column of total_columns_array; let j=index">
			                            		<ng-container [formGroup]="t.controls[j]">
					                            	Column {{httpClientService.numberToLetter(total_column)}}
					                            	<span *ngIf="!(taskStepData && (!taskStepData.boolean_question || (!result.task_modified_data[total_column])))">{{result.task_modified_data[total_column]}}</span>
					                            	<!--  [ngClass]="{ 'is-invalid': submitted && t.controls[j].controls.column_name.errors }" -->
					                            	<span *ngIf="taskStepData && (!taskStepData.boolean_question || (!result.task_modified_data[total_column]))"><input type="text" formControlName="column_name"></span>
					                            </ng-container>
				                            </th>
			                            </tr>
		                            </thead>
		                        	<tbody>
		                                <tr *ngFor="let result of taskData.task_details.result | slice:is_header:3;let i=index;">
			                            	<td *ngFor="let total_column of total_columns_array; let j=index">{{result.task_modified_data[total_column]}}</td>
			                            </tr>
		                            </tbody>
	                            </table>
	                            <div class="gradient-fade-out"></div>
	                        </div>
	                    </div>
	                    <div class="w-full d-sm-flex mt-16">
	                     	<button class="bg-blue-800">Review Dataset </button>
	                    </div>
	                </form>
                </div>
          	</div>
	    </div>        
  	</div>
</div>