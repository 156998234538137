<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
  	<div class="container">
	      <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>

	         <div class="dashborad-rightsider">
                <div class="edit-profile-set add-teamuserlist-page">
                     <div class="list-top-attribute">
                        <h3> Add task </h3>
                        <div class="rightattributepanel">
                        	
                           <a [routerLink]="['/tasks']" class="btn blue-custom-btn backbtn"> <i class="fa fa-long-arrow-left"></i> Back </a>
                        </div>
                     </div>
                     <div class="viewmanagercontent">
                        <form [formGroup]="taskForm" (ngSubmit)="saveTask()">
                          <div class="row">
                          	<div class="col-lg-12">
                            	<div class="addlableinformation">
                                	<label class="custom-label">Title </label>
                                    <div class="form-group">
                                        <input type="text" class="form-control without-append-input" placeholder="Enter title" formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                          <div *ngIf="f.title.errors.required">Title is required</div>
                                          <div *ngIf="f.title.errors.maxlength">Title should be max {{f.title.errors.maxlength.requiredLength}} characters</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-12">
                              <div class="addlableinformation">
                                  <label class="custom-label">Team Users </label>
                                    <div class="form-group">
                                      <ng-select2 formControlName="user_ids" [options]="{multiple: true}" [data]="teamUserData"></ng-select2>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-12">
                            	<div class="addlableinformation">
                                	<label class="custom-label">Description </label>
                                    <div class="form-group">
                                        <textarea type="text" class="form-control without-append-input" placeholder="Enter description" formControlName="description" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                          <div *ngIf="f.description.errors.required">Description is required</div>
                                          <div *ngIf="f.description.errors.maxlength">Description should be max {{f.description.errors.maxlength.requiredLength}} characters</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <form method="post" id="fileupload" enctype="multipart/form-data" action="/admin/email-templates/fileupload" novalidate>
                                    <label for="file">Task File</label>
                                    <div class="avatar-upload">
                                        <div class="avatar-edit">
                                            <div class="change-profile-photo" style="margin: 0;">
                                                <input type="file" ng2FileSelect [uploader]="uploader">
                                                <span> Add Task File </span>
                                            </div>
                                            <div style="padding-top: 2%;">
                                                <!-- Queue progress: -->
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }">{{uploader.progress}} %</div>
                                                </div>
                                            </div>
                                            <!-- <input type="file" id="fileUpload" name="file" accept=".xlsx, .csv" />
                                            <label for="fileUpload"></label> -->
                                            <!-- <button type="submit" class="btn btn-primary btn-sm">Submit</button> -->
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="col-lg-12">
                            	<div class="addlableinformation statusaction">
                                	<label class="custom-label">Status</label>
                                    <label class="switch">
                                      <input type="checkbox" formControlName="status">
                                      <span class="switchslider round"></span>
                                    </label>
                                </div>
                            </div>
                            
                            <div class="col-sm-12">
                                  <div class="form-group text-center">
                                    <div class="btn submit-btn">
                                       <input type="submit" value="Add task"> 
                                       <i class="fa fa-angle-right"></i>
                                    </div>
                                   </div>
                            </div>
                            
                          </div>
                        </form>
                     </div>
                        
                </div>
            </div>
	      </div>        
  	</div>
</div>