<div  uploadxDrop class="mt-12 rounded-xl dropzone">
  <div *ngIf="state$ | async as state; else noFile">
    <div class="progress-status" *ngIf="state.status == 'uploading'">
      <h3>Your file is uploading... please wait</h3>
      <mat-progress-bar style="background-color: #2b54a3;" mode="determinate" [value]="state.progress"></mat-progress-bar>
    </div>
  </div>
</div>


<ng-template #noFile>
  <input style="display: none;" accept=".csv,.xlsx" id="files" type="file" uploadx #fileinput />
  <div class="upload-form" (click)="fileinput.click()" >
    <div>
      <img src="assets/images/cloud-upload-alt-duotone.svg" alt="img">
      <h3>Drop files here or click to upload</h3>
    </div>
  </div>
</ng-template>