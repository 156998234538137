<!--banner start-->
<div class="banner-section inner-banner-section">


</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
	<div class="container">
		<div class="dashborad-panel">
			<app-businesssidemenu></app-businesssidemenu>

			<div class="dashborad-rightsider">
				<div class="text-right mb-2" *ngIf="taskData">
					<a [routerLink]="" (click)="exportData('excel')" class="btn blue-custom-btn mr-2"> <i class="fa fa-download"></i> Excel </a>
					<a [routerLink]="" (click)="exportData('csv')" class="btn blue-custom-btn"> <i class="fa fa-download"></i> CSV </a>
				</div>
				<div class="edit-profile-set view-team-manager-page" *ngIf="taskData">
					<div class="list-top-attribute">
						<h3> View Uncategorized Tasks </h3>
						<div class="rightattributepanel">
							<div class="attributesearch">
								<input type="text" class="form-control" placeholder="Search task data"
									[(ngModel)]="searchKeyword" (ngModelChange)="searchByKeyword()">
								<img src="assets/images/black-search.png" alt="img">
							</div>
							<a [routerLink]="['/business-user/tasks']" class="btn blue-custom-btn backbtn"> <i
									class="fa fa-long-arrow-left"></i> Back </a>
						</div>
					</div>

					<div class="table-data">
						<div class="table-responsive">
							<table class="table">
								<thead>
									<tr>
										<th *ngFor="let task_data of taskData.task.task_header;let i=index;">
											{{task_data}}</th>
									</tr>
								</thead>
								<tbody>
									<ng-container *ngIf="taskData && taskData.task_details.result.length">
										<tr *ngFor="let task_data of taskData.task_details.result">
											<td *ngFor="let data of task_data.task_modified_data;let i=index;"><span class="name-user">{{data}}</span></td>
										</tr>
									</ng-container>

									<ng-container *ngIf="!(taskData && taskData.task_details.result.length)">
										<tr>
											<td colspan="500" class="text-center">No record found!</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
						<div class="table-show-pagination">
							<div class="show-item" style="margin-bottom: 2%;">
								<span>Showing</span>
								<select class="form-control" id="pagelimit" [(ngModel)]="perPageLimit" (change)="onPageLimitChange($event)">
                                	<option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
								</select>
								<span> records out of
									{{count}} total</span>
							</div>
							<ul class="pagination" style="overflow: auto;">
								<li class="page-item {{!prevPage?'disabled':''}}">
									<a [routerLink]="" class="page-link pageprev" (click)="gotoPrevious()">Prev</a>
								</li>

								<ul class="pagination" style="overflow: auto;border: none;">
									<li *ngFor="let page of pageArray;let i=index;" class="page-item">
										<a [routerLink]="" class="page-link {{activePage==page ? 'active' : ''}}"
											(click)="gotoPage(i+1)">{{page}}</a>
									</li>
								</ul>

								<li class="page-item {{!nextPage?'disabled':''}}">
									<a [routerLink]="" class="page-link pagenext" (click)="gotoNext()">Next</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>