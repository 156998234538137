<!--banner start-->
<div class="banner-section inner-banner-section">
  
  
</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
  	<div class="container">
	      <div class="dashborad-panel">
	        <app-businesssidemenu></app-businesssidemenu>

	         <div class="dashborad-rightsider">
                <div class="edit-profile-set add-teamuserlist-page">
                     <div class="list-top-attribute">
                        <h3> Add team </h3>
                        <div class="rightattributepanel">
                        	
                           <a [routerLink]="['/teams']" class="btn blue-custom-btn backbtn"> <i class="fa fa-long-arrow-left"></i> Back </a>
                        </div>
                     </div>
                     <div class="viewmanagercontent">
                        <form [formGroup]="teamForm" (ngSubmit)="saveTeam()">
                          <div class="row">
                          	<div class="col-lg-12">
                            	<div class="addlableinformation">
                                	<label class="custom-label">Team name </label>
                                    <div class="form-group">
                                        <input type="text" class="form-control without-append-input" placeholder="Team name" formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                          <div *ngIf="f.title.errors.required">Team name is required</div>
                                          <div *ngIf="f.title.errors.maxlength">Team name should be max {{f.title.errors.maxlength.requiredLength}} characters</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="addlableinformation">
                                  <label class="custom-label">Select team users </label>
                                    <div class="form-group">
                                      <ng-select2 formControlName="user_ids" [options]="{multiple: true}" [data]="teamUserData"></ng-select2>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                            	<div class="addlableinformation">
                                	<label class="custom-label">Description </label>
                                    <div class="form-group">
                                        <textarea type="text" class="form-control without-append-input" placeholder="Enter description" formControlName="description" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                          <div *ngIf="f.description.errors.required">Description is required</div>
                                          <div *ngIf="f.description.errors.maxlength">Description should be max {{f.description.errors.maxlength.requiredLength}} characters</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                            	<div class="addlableinformation statusaction">
                                	<label class="custom-label">Enabled</label>
                                    <label class="switch">
                                      <input type="checkbox" formControlName="status">
                                      <span class="switchslider round"></span>
                                    </label>
                                </div>
                            </div>
                            
                            <div class="col-sm-12">
                              <div class="form-group text-center">
                                <button type="submit" class="btn submit-btn">
                                   Add team
                                   <i class="fa fa-angle-right"></i>
                                </button>
                              </div>
                            </div>
                            
                          </div>
                        </form>
                     </div>
                        
                </div>
            </div>
	      </div>        
  	</div>
</div>

<!-- Modal -->
<div class="modal fade" id="limitExceedModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Limit Exceed</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="limitExceedMessage"></div>
      <div class="modal-footer popup-btns">
        <button type="button" class="submit-btn cancel" data-dismiss="modal">Cancel</button>
        <a [routerLink]="['/upgradeplan']" class="submit-btn" >Upgrade</a>
      </div>
    </div>
  </div>
</div>