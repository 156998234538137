<!--banner start-->
<div class="banner-section inner-banner-section">


</div>
<!--banner end-->

<!--login page-->
<div class="inner-section object-comman object-comman-reverse after-login-section">
    <div class="container">
        <div class="dashborad-panel">
            <app-businesssidemenu></app-businesssidemenu>

            <div class="dashborad-rightsider">
                <div class="edit-profile-set add-teamuserlist-page">
                    <div class="list-top-attribute">
                        <h3> Add task </h3>
                        <!-- <div class="rightattributepanel">

                            <a [routerLink]="['/business-user/tasks']" class="btn blue-custom-btn backbtn"> <i
                                    class="fa fa-long-arrow-left"></i> Back </a>
                        </div> -->
                    </div>
                    <div class="viewmanagercontent">
                        <form [formGroup]="taskHeaderForm" (ngSubmit)="updateTaskHeader()">
                            <div class="row">
                                <ng-container *ngFor="let task_header of t.controls; let i=index">
                                    <div class="col-lg-3">
                                        <div class="addlableinformation">
                                            <label class="custom-label">Header {{i + 1}} </label>
                                            <div class="form-group" [formGroup]="task_header">
                                                <input type="text" class="form-control without-append-input"
                                                    placeholder="Enter Header {{i + 1}} value" formControlName="name"
                                                    [ngClass]="{ 'is-invalid': submitted && task_header.controls.name.errors }">
                                                <div *ngIf="submitted && task_header.controls.name.errors" class="invalid-feedback">
                                                    <div *ngIf="task_header.controls.name.errors.required">Header {{i + 1}} is required</div>
                                                    <div *ngIf="task_header.controls.name.errors.maxlength">Value should be max {{task_header.controls.name.errors.maxlength.requiredLength}} characters</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="col-sm-12">
                                    <div class="form-group text-center">
                                        <div class="btn submit-btn">
                                            <input type="submit" value="Update task headers">
                                            <i class="fa fa-angle-right"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>