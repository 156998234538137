<!--banner start-->
  <div class="banner-section inner-banner-section">



  </div>
  <!--banner end-->

  <!--login page-->
<div class="inner-section loginver1-page">
    <div class="container">
        <div class="my-12">
            <div class="shadow-xl rounded-xl innershape innershapebg">
                <div class="bg-blue-700 text-white striop rounded-tl-xl rounded-tr-xl">Create an account</div>
                <div class="py-4">
                <form [formGroup]="signupForm" (ngSubmit)="signupUser()">
                    <div class="px-4">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                              <label>First Name</label>
                              <input type="text" class="form-control" placeholder="Enter first name" formControlName="first_name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                              <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                  <div *ngIf="f.first_name.errors.required">First name is required</div>
                                  <div *ngIf="f.first_name.errors.maxlength">First name should be max {{f.first_name.errors.maxlength.requiredLength}} characters</div>
                              </div>
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group">
                              <label>Last Name</label>
                              <input type="text" class="form-control" placeholder="Enter last name" formControlName="last_name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                              <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                  <div *ngIf="f.last_name.errors.required">Last name is required</div>
                                  <div *ngIf="f.last_name.errors.maxlength">Last name should be max {{f.last_name.errors.maxlength.requiredLength}} characters</div>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                              <label>Email</label>
                              <input type="text" class="form-control shadow-sm" placeholder="Enter email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                              </div>
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group">
                              <label>Contact Number</label>
                              <input type="text" appPhoneMask maxLength="12" class="form-control" placeholder="Enter contact number" formControlName="mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                  <div *ngIf="f.mobile.errors.required">Contact Number is required</div>
                                  <div *ngIf="f.mobile.errors.range && f.mobile.errors.range.requiredMaxLength">Contact Number should be max {{f.mobile.errors.range.requiredMaxLength}} digit</div>
                                  <div *ngIf="f.mobile.errors.range && f.mobile.errors.range.requiredMinLength">Contact Number should be min {{f.mobile.errors.range.requiredMinLength}} digit</div>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                              <label>Company</label>
                              <input type="text" class="form-control" placeholder="Enter Company" formControlName="company_name" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }">
                              <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                                <div *ngIf="f.company_name.errors.required">Company is required</div>
                                <div *ngIf="f.company_name.errors.maxlength">Company should be max {{f.company_name.errors.maxlength.requiredLength}} characters</div>
                              </div>
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group signup-select2" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                              <label>Select Location</label>
                              <ng-select2 formControlName="country" [options]="{multiple: false}" [placeholder]="'Select location'" [data]="countiresData"></ng-select2>
                              <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                <div *ngIf="f.country.errors.required">Location is required</div>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Password</label>
                            <input type="password" placeholder="Password" class="form-control shadow-sm" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                              <div *ngIf="f.password.errors.required">Password is required</div>
                              <div *ngIf="f.password.errors.minlength">Password must be at least {{f.password.errors.minlength.requiredLength}} characters</div>
                              <div *ngIf="f.password.errors.pattern">Password should have at least 8 characters with one small letter, upper letter, a special character and a number</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>Confirm Password</label>
                              <input type="password" class="form-control" placeholder="Enter confirm password" formControlName="repassword" [ngClass]="{ 'is-invalid': submitted && f.repassword.errors }">
                              <div *ngIf="submitted && f.repassword.errors" class="invalid-feedback">
                                  <div *ngIf="f.repassword.errors.required">Confirm Password is required</div>
                                  <div *ngIf="f.repassword.errors.minlength">Confirm Password must be at least {{f.repassword.errors.minlength.requiredLength}} characters</div>
                                  <div *ngIf="f.repassword.errors.pattern">Password should have at least 8 characters with one small letter, upper letter, a special character and a number</div>
                                  <div *ngIf="f.repassword.errors.mustMatch">Password and confirm password should be the same</div>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between align-items-center rem">
                        <label class="d-flex align-items-center" [ngClass]="{ 'invalid-feedback': submitted && signupForm.value.term_of_use == false }"><input type="checkbox" formControlName="term_of_use"><span style="font-size: 1rem;">I accept the <a [routerLink]="['/page/terms-and-conditions']">Terms of use</a> &amp; <a [routerLink]="['/page/privacy-policy']">Privacy policy</a></span></label>
                      </div>

                    </div>

                    <div class="btntoper">
                        <button class="shadow-xl mt-3 bg-blue-700">Sign up</button>
                      </div>
                </form>
                </div>
            </div>

            <a class="dont" [routerLink]="['/login']">Already have an account? Sign In</a>
        </div>
    </div>
</div>

<!-- <div class="banner-section credentials-panel-new">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
            <figure class="login-attributes">
              <img src="assets/images/login-attributes.png" alt="typing"/>
            </figure>
        </div>
        <div class="col-md-7">
          <div class="credentials-panel">
               <div class="crendentails-right-section registration-right">
                      <div class="page-heading text-center">
                                <img src="assets/images/heading-object.png" alt="heading-object">
                                <h2>Create an account </h2>
                      </div>
                      <form [formGroup]="signupForm" (ngSubmit)="signupUser()">
                       <div class="row">
                             <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <input type="text" class="form-control" placeholder="Enter name" formControlName="name">
                                         <img src="assets/images/user.png" alt="user"/>
                                    </div>
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                        <div *ngIf="f.name.errors.maxlength">Name should be max {{f.name.errors.maxlength.requiredLength}} characters</div>
                                    </div>
                                </div>
                            </div>
                             <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }">
                                        <input type="text" class="form-control" placeholder="Enter Company" formControlName="company_name">
                                         <img src="assets/images/company-o.png" alt="company"/>
                                    </div>
                                    <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                                        <div *ngIf="f.company_name.errors.required">Company is required</div>
                                        <div *ngIf="f.company_name.errors.maxlength">Company should be max {{f.company_name.errors.maxlength.requiredLength}} characters</div>
                                    </div>
                                </div>
                            </div>
                             <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <input type="text" class="form-control" placeholder="Enter email" formControlName="email">
                                         <img src="assets/images/email.png" alt="email"/>
                                    </div>
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                        <input type="number" class="form-control" placeholder="Enter mobile" formControlName="mobile">
                                         <img src="assets/images/phone-o.png" alt="Mobile"/>
                                    </div>
                                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.mobile.errors.required">Mobile number is required</div>
                                        <div *ngIf="f.mobile.errors.range && f.mobile.errors.range.requiredMaxLength">Mobile number should be max {{f.mobile.errors.range.requiredMaxLength}} digit</div>
                                        <div *ngIf="f.mobile.errors.range && f.mobile.errors.range.requiredMinLength">Mobile number should be min {{f.mobile.errors.range.requiredMinLength}} digit</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group mb-4">
                                    <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                        <input type="password" class="form-control" placeholder="Enter password" formControlName="password">
                                         <img src="assets/images/password.png" alt="password"/>
                                    </div>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be at least {{f.password.errors.minlength.requiredLength}} characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group mb-4">
                                    <div class="append-icon" [ngClass]="{ 'is-invalid': submitted && f.repassword.errors }">
                                        <input type="password" class="form-control" placeholder="Enter confirm password" formControlName="repassword">
                                         <img src="assets/images/password.png" alt="password"/>
                                    </div>
                                    <div *ngIf="submitted && f.repassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.repassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="f.repassword.errors.minlength">Confirm Password must be at least {{f.repassword.errors.minlength.requiredLength}} characters</div>
                                        <div *ngIf="f.repassword.errors.mustMatch">Password and confirm password should be the same</div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-sm-12">
                            	<div class="check-custom">
                                    <input type="checkbox" class="form-control" formControlName="term_of_use">
                                    <label [ngClass]="{ 'invalid-feedback': submitted && signupForm.value.term_of_use == false }">I accept the <a [routerLink]="['/page/terms-and-conditions']">Terms of use</a> &amp; <a [routerLink]="['/page/privacy-policy']">Privacy policy</a></label>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                  <div class="form-group text-center">
                                    <button type="submit" class="btn submit-btn">
                                       Sign up
                                       <i class="fa fa-angle-right"></i>
                                    </button>
                                   </div>
                            </div>

                        </div>
                      </form>
                      <div class="or-panel text-center">
                      	<span>OR</span>
                        <div class="or-linktext"> Already have an account?  <a [routerLink]="['/login']">Sign In</a></div>
                      </div>
                </div>

          </div>
        </div>
    </div>
</div> -->
