import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

const regArr = [
  /(02\d{0,1})(\d{0,4})(\d{0,4})/,
  /(03\d{0,2})(\d{0,3})(\d{0,4})/,
  /(05\d{0,3})(\d{0,6})/,
  /(07\d{0,3})(\d{0,6})/,
  /(08\d{0,2})(\d{0,3})(\d{0,4})/,
  /(09\d{0,2})(\d{0,3})(\d{0,4})/
];

@Directive({
  inputs: ['maxLength'],
  selector: "[formControlName][appPhoneMask]"
})
export class PhoneMaskDirective {
  maxLength: string;
  constructor(public ngControl: NgControl) {}

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener("keydown.backspace", ["$event"])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  filterNumber(val: string) {
    if (val.length < 2) return null;
    for (let i = 0; i < regArr.length; i++) {
      const res = regArr[i].exec(val);
      if (regArr[i].exec(val))
        return res
          .slice(1)
          .filter(section => section && section.length > 0)
          .join(" ");
    }
    return null;
  }

  onInputChange(newVal, backspace) {
    if (backspace && newVal.length > 0) {
      newVal = newVal.substring(0, newVal.length);
    }
    newVal = newVal.replace(/\D/g, "");
    const len = parseInt(this.maxLength || "");
    if (!isNaN(len)) {
      newVal = newVal.substring(0, len);
    }
    let filtered = this.filterNumber(newVal);
    if (filtered) {
      return this.ngControl.valueAccessor.writeValue(filtered);
    }
    this.ngControl.valueAccessor.writeValue(newVal);
  }
}
